import { Popover, Stack, Typography } from '@mui/material';
import Checkbox from 'components/checkbox/checkbox';
import { SearchIcon } from 'icons/search';
import { DropDownProps, TasksFiltersEnum } from 'pages/tasks/types';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { AppState } from 'redux/store';
import { ChangeEvent, useEffect, useState } from 'react';
import { notify } from 'notifications';
import Skeleton from 'components/ui-new/skeleton/skeleton';
import { UserShortModel } from 'services/user.model';
import { PaginateResponse } from 'types/paginate-response';
import { FailReasonsModel } from 'services/production-task.model';
import { IdName } from 'types/common-types';
import s from './drop-down-search-filter.module.scss';
import floatingFilterStyle from '../floating-filter/floating-filter.module.scss';

type Props = {
  fieldName: TasksFiltersEnum;
  fetchOptionsFunction?: () => Promise<PaginateResponse<UserShortModel> | FailReasonsModel>;
} & DropDownProps;

const skeleton = Array(5)
  .fill(null)
  .map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Stack flexDirection="row" width="100%" gap="6px" key={index}>
      <Skeleton width="20px" />
      <Skeleton width="100%" />
    </Stack>
  ));

const DropDownSearchFilter = ({ isOpen, anchorEl, fieldName, fetchOptionsFunction, onClose }: Props) => {
  const dispatch = useDispatch();
  const filter = useSelector((state: AppState) => state.tasks.filters.filters[fieldName]);
  const [searchValue, setSearchValue] = useState(filter?.value || '');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const handleSelect = (id: string) => {
    dispatch(TasksActions.onFilterChange(fieldName, searchValue, id));
  };

  const handleSelectAll = () => {
    const allSelected = filter.options?.every((option) => filter.selectedOptions.includes(option.id));

    if (allSelected) {
      filter.selectedOptions.forEach((optionId) => {
        dispatch(TasksActions.onFilterChange(fieldName, searchValue, optionId));
      });
    } else {
      filter.options?.forEach((option) => {
        if (!filter.selectedOptions.includes(option.id)) {
          dispatch(TasksActions.onFilterChange(fieldName, searchValue, option.id));
        }
      });
    }
  };

  const handleReset = () => {
    filter.selectedOptions.forEach((optionId) => {
      dispatch(TasksActions.onFilterChange(fieldName, searchValue, optionId));
    });

    setSearchValue('');
  };

  const filteredOptions = filter?.options?.filter((option) => option.name.toLowerCase().includes(searchValue.toLowerCase()));

  const isAllSelected = filter?.options?.every((option) => filter.selectedOptions.includes(option.id));

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        if (!fetchOptionsFunction || filter?.options.length || !isOpen) return;

        setIsLoading(true);

        const response = await fetchOptionsFunction();

        let mappedOptions: IdName[] = [];

        switch (fieldName) {
          case TasksFiltersEnum.ReasonForFailure:
            if (Array.isArray(response)) {
              mappedOptions = response.map((option: FailReasonsModel) => ({
                id: option.en_reason,
                name: option.en_reason,
              }));
            }
            break;

          case TasksFiltersEnum.Assignee:
            if ('data' in response) {
              mappedOptions = [
                { id: 'unassigned', name: 'Unassigned' },
                ...response.data.map((option: UserShortModel) => ({
                  id: option.id,
                  name: `${option.first_name} ${option.last_name}`,
                  avatar_image_url: option.avatar_image_url,
                })),
              ];
            }
            break;

          default:
            return;
        }

        dispatch(TasksActions.setFilterOptions(fieldName, mappedOptions));
      } catch (error) {
        notify.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [fieldName, filter, fetchOptionsFunction, dispatch, isOpen]);

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      slotProps={{ paper: { className: floatingFilterStyle.popover } }}
    >
      <Stack gap="8px" alignItems="flex-end">
        <Stack width="100%" position="relative">
          <input className={`${floatingFilterStyle.input} ${s.search_input}`} value={searchValue} onChange={handleInputChange} />
          <SearchIcon className={s.search_icon} width={18} height={18} stroke="#141414" />
        </Stack>
        {isLoading ? (
          skeleton
        ) : (
          <Stack width="100%" gap="6px" alignSelf="flex-start" maxHeight="350px" overflow="scroll">
            <Stack flexDirection="row" alignItems="center" gap="6px">
              <Checkbox size="medium" checked={isAllSelected} onChange={handleSelectAll} />
              <Typography>Select all</Typography>
            </Stack>
            {filteredOptions?.map((item) => (
              <Stack flexDirection="row" alignItems="center" gap="6px" key={item.id} onClick={() => handleSelect(item.id)}>
                <Checkbox size="medium" checked={filter.selectedOptions.includes(item.id)} />
                <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                  {item.name}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
        <button className={s.apply_btn} type="button" onClick={handleReset}>
          Reset
        </button>
      </Stack>
    </Popover>
  );
};

export default DropDownSearchFilter;
