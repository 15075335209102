import { AppState } from 'redux/store';
import { PageName } from 'types/common-enums';
import { StateController } from 'state-controller';

type MultiselectState = {
  isMultiselect: boolean;
  selectedProductIds: string[];
  selectedCategoryIds: string[];
  selectedPositionIds: string[];
  selectedDepartmentIds: string[];
};

const defaultState: MultiselectState = {
  isMultiselect: false,
  selectedProductIds: [],
  selectedCategoryIds: [],
  selectedPositionIds: [],
  selectedDepartmentIds: [],
};

const stateController = new StateController<MultiselectState>('MULTISELECT', defaultState);

export class Actions {
  public static disposeState() {
    return async (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static changeMultiselectMode(value: boolean) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isMultiselect: value }));
      dispatch(Actions.deselectAll());
    };
  }

  public static selectAll(page: PageName) {
    return async (dispatch) => {
      dispatch(Actions.selectAllProducts(page));
      dispatch(Actions.selectAllCategories(page));
      dispatch(Actions.selectAllPositions(page));
      dispatch(Actions.selectAllDepartments(page));
    };
  }

  public static deselectAll() {
    return async (dispatch) => {
      dispatch(Actions.deselectAllProducts());
      dispatch(Actions.deselectAllCategories());
      dispatch(Actions.deselectAllPositions());
      dispatch(Actions.deselectAllDepartments());
    };
  }

  // ====== Product =====================================================================================================================

  public static selectProduct(id: string) {
    return async (dispatch, getState: () => AppState) => {
      const { isMultiselect } = getState().products.multiselect;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedProductIds: [...prev.selectedProductIds, id],
        })),
      );
      if (!isMultiselect) {
        dispatch(stateController.setState({ isMultiselect: true }));
      }
    };
  }

  public static deselectProduct(id: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedProductIds: prev.selectedProductIds.filter((i) => i !== id),
        })),
      );
    };
  }

  public static selectAllProducts(page: PageName) {
    return async (dispatch, getState: () => AppState) => {
      let productIds: string[];

      switch (page) {
        case PageName.AllProducts:
          productIds = getState().products.products.items.map((i) => i.product_meta.id);
          break;
        case PageName.ProductSearch:
          productIds = getState().productSearch.productItems.map((i) => i.product_meta.id);
          break;
        case PageName.Departments:
          productIds = [];
          break;
        default:
          break;
      }

      dispatch(stateController.setState({ selectedProductIds: [...productIds] }));
    };
  }

  public static deselectAllProducts() {
    return async (dispatch) => {
      dispatch(stateController.setState({ selectedProductIds: [] }));
    };
  }

  // ====== Category ====================================================================================================================

  public static selectCategory(id: string) {
    return async (dispatch, getState: () => AppState) => {
      const { isMultiselect } = getState().products.multiselect;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedCategoryIds: [...prev.selectedCategoryIds, id],
        })),
      );
      if (!isMultiselect) {
        dispatch(stateController.setState({ isMultiselect: true }));
      }
    };
  }

  public static deselectCategory(id: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedCategoryIds: prev.selectedCategoryIds.filter((i) => i !== id),
        })),
      );
    };
  }

  public static selectAllCategories(page: PageName) {
    return async (dispatch, getState: () => AppState) => {
      let categoryIds: string[];

      switch (page) {
        case PageName.AllProducts:
          categoryIds = getState().products.categories.items.map((i) => i.id);
          break;
        case PageName.ProductSearch:
          categoryIds = [];
          break;
        case PageName.Departments:
          categoryIds = [];
          break;
        default:
          break;
      }

      dispatch(stateController.setState({ selectedCategoryIds: [...categoryIds] }));
    };
  }

  public static deselectAllCategories() {
    return async (dispatch) => {
      dispatch(stateController.setState({ selectedCategoryIds: [] }));
    };
  }

  // ====== Position ====================================================================================================================

  public static selectPosition(id: string) {
    return async (dispatch, getState: () => AppState) => {
      const { isMultiselect } = getState().products.multiselect;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedPositionIds: [...prev.selectedPositionIds, id],
        })),
      );
      if (!isMultiselect) {
        dispatch(stateController.setState({ isMultiselect: true }));
      }
    };
  }

  public static deselectPosition(id: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedPositionIds: prev.selectedPositionIds.filter((i) => i !== id),
        })),
      );
    };
  }

  public static selectAllPositions(page: PageName) {
    return async (dispatch, getState: () => AppState) => {
      let positionIds: string[];

      switch (page) {
        case PageName.AllProducts:
          positionIds = [];
          break;
        case PageName.ProductSearch:
          positionIds = [];
          break;
        case PageName.Departments:
          positionIds = getState().departments.positions.map((i) => i.id);
          break;
        default:
          break;
      }

      dispatch(stateController.setState({ selectedPositionIds: [...positionIds] }));
    };
  }

  public static deselectAllPositions() {
    return async (dispatch) => {
      dispatch(stateController.setState({ selectedPositionIds: [] }));
    };
  }

  // ====== Department ==================================================================================================================

  public static selectDepartment(id: string) {
    return async (dispatch, getState: () => AppState) => {
      const { isMultiselect } = getState().products.multiselect;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedDepartmentIds: [...prev.selectedDepartmentIds, id],
        })),
      );
      if (!isMultiselect) {
        dispatch(stateController.setState({ isMultiselect: true }));
      }
    };
  }

  public static deselectDepartment(id: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedDepartmentIds: prev.selectedDepartmentIds.filter((i) => i !== id),
        })),
      );
    };
  }

  public static selectAllDepartments(page: PageName) {
    return async (dispatch, getState: () => AppState) => {
      let departmentIds: string[];

      switch (page) {
        case PageName.AllProducts:
          departmentIds = [];
          break;
        case PageName.ProductSearch:
          departmentIds = [];
          break;
        case PageName.Departments:
          departmentIds = getState().departments.departments.map((i) => i.id);
          break;
        default:
          break;
      }

      dispatch(stateController.setState({ selectedDepartmentIds: [...departmentIds] }));
    };
  }

  public static deselectAllDepartments() {
    return async (dispatch) => {
      dispatch(stateController.setState({ selectedDepartmentIds: [] }));
    };
  }
}

export class Selectors {
  public static selectedItemsCount(state: AppState) {
    const selectedProductIds = state.products.multiselect.selectedProductIds.length;
    const selectedCategoryIds = state.products.multiselect.selectedCategoryIds.length;
    return selectedProductIds + selectedCategoryIds;
  }

  public static selectedDepartmentsAndPositionsCount(state: AppState) {
    const selectedDepartmentIds = state.products.multiselect.selectedDepartmentIds.length;
    const selectedPositionIds = state.products.multiselect.selectedPositionIds.length;
    return selectedDepartmentIds + selectedPositionIds;
  }
}

export const reducer = stateController.getReducer();
