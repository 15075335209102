import { MenuItem, Popover, Select, SelectChangeEvent, Stack } from '@mui/material';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { MenuItemType } from 'pages/tasks/modules/drop-down-select-filter/types';
import { ChangeEvent, useState } from 'react';
import { DropDownProps, FilterSettingEnum, TasksFiltersEnum } from 'pages/tasks/types';
import { useDispatch } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import s from './drop-down-select-filter.module.scss';
import floatingFilterStyle from '../floating-filter/floating-filter.module.scss';

type Props = {
  menuItems: MenuItemType[];
  fieldName: TasksFiltersEnum;
} & DropDownProps;

const DropDownSelectFilter = ({ isOpen, anchorEl, menuItems, fieldName, onClose }: Props) => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(menuItems[0].value);
  const [searchValue, setSearchValue] = useState('');

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedValue(event.target.value);

    dispatch(TasksActions.onFilterChange(fieldName, event.target.value === FilterSettingEnum.Blank ? '' : searchValue));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    dispatch(TasksActions.onFilterChange(fieldName, event.target.value));
  };

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      slotProps={{ paper: { className: floatingFilterStyle.popover } }}
    >
      <Stack gap="8px">
        <Select
          className={s.select}
          fullWidth
          value={selectedValue}
          IconComponent={KeyboardArrowDownOutlined}
          onChange={handleSelectChange}
          MenuProps={{ slotProps: { paper: { className: s.menu_container } } }}
        >
          {menuItems.map((item) => (
            <MenuItem className={s.menu_item} data-hidden={item.value === selectedValue} value={item.value} key={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
        <input
          className={floatingFilterStyle.input}
          disabled={selectedValue === FilterSettingEnum.Blank}
          value={searchValue}
          onChange={handleInputChange}
        />
      </Stack>
    </Popover>
  );
};

export default DropDownSelectFilter;
