import { Popover } from '@mui/material';
import RangeSelector from 'pages/tasks/components/range-selector/range-selector';
import { DropDownProps, TasksFiltersEnum } from 'pages/tasks/types';
import floatingFilterStyle from '../floating-filter/floating-filter.module.scss';

type Props = {
  fieldName: TasksFiltersEnum;
} & DropDownProps;

const DropDownDateFilter = ({ isOpen, anchorEl, fieldName, onClose }: Props) => {
  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      slotProps={{ paper: { className: floatingFilterStyle.popover, style: { width: 'auto' } } }}
    >
      <RangeSelector fieldName={fieldName} />
    </Popover>
  );
};

export default DropDownDateFilter;
