import { FC } from 'react';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { ArrowLongLeftIcon } from 'icons/arrow-long-left';
import { getValidDateValues } from 'pages/production/controllers/helpers';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { MultiInputDateRangeField } from '@mui/x-date-pickers-pro/MultiInputDateRangeField';
import s from './custom-calendar-layout.module.scss';

type OwnProps = {
  value?: DateRange<Dayjs>;
  onChange: (dateRange: DateRange<Dayjs>) => void;
};

const CustomCalendarLayout: FC<OwnProps> = ({ value, onChange }) => {
  const validValue = getValidDateValues(value);

  const separator = () => <ArrowLongLeftIcon className={s.separator_icon} />;

  const handleOnChange = (val: DateRange<Dayjs>) => {
    const newValue = getValidDateValues(val);
    const isArraysEqual = validValue.every((v, index) => v === newValue[index]);

    if (isArraysEqual) return;

    onChange(newValue);
  };

  return (
    <div className={s.container}>
      <div className={s.multi_input_container}>
        <MultiInputDateRangeField
          value={validValue}
          format="DD.MM.YYYY"
          slots={{ separator }}
          classes={{ root: s.multi_input }}
          onChange={(newValue) => handleOnChange(newValue)}
          slotProps={{
            textField: () => ({
              inputProps: {
                placeholder: 'DD.MM.YYYY',
              },
            }),
          }}
        />
      </div>
      <DateRangeCalendar
        reduceAnimations
        value={validValue}
        className={s.date_picker}
        onChange={(newValue) => handleOnChange(newValue)}
        classes={{ monthContainer: s.date_header }}
        slotProps={{
          nextIconButton: { className: s.date_button },
          previousIconButton: { className: s.date_button },
        }}
      />
    </div>
  );
};

export default CustomCalendarLayout;
