import DropdownMenu from 'components/dropdown-menu/dropdown-menu';
import { ICellRendererParams } from 'ag-grid-community';
import { OpenIcon } from 'icons/open';
import { OpenRootIcon } from 'icons/open-root';
import { OpenSecondaryIcon } from 'icons/open-secondary';
import { ArrowSquareRight } from 'icons/arrow-square-right';
import { useDispatch } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { TaskTableModel } from 'services/task-table.model';
import { CopyIcon } from '../../../../icons/copy';

type Props = ICellRendererParams<TaskTableModel>;

const ActionsMenu = ({ ...props }: Props) => {
  const dispatch = useDispatch();
  const { data } = props;
  const copyLink = {
    onClick: () => dispatch(TasksActions.copyTaskLink(data?.id)),
    key: 'copy_url_for_task',
    text: 'Copy link',
    bottomDivider: false,
    icon: <CopyIcon width="16" height="16" />,
  };

  const openTask = {
    onClick: () => dispatch(TasksActions.openTaskInNewTab(data?.id)),
    key: 'action_open_task',
    text: 'Open task',
    bottomDivider: false,
    icon: <OpenIcon width={16} height={16} />,
  };

  const openRootProduction = {
    onClick: () => dispatch(TasksActions.openRootOrMainProductionInNewTab(data?.root_production_workflow_id)),
    key: 'action_open_root_production',
    text: 'Open root production',
    bottomDivider: false,
    icon: <OpenRootIcon width={16} height={16} />,
  };

  const openProduction = {
    onClick: () => dispatch(TasksActions.openRootOrMainProductionInNewTab(data?.production_workflow_id)),
    key: 'action_open_production',
    text: 'Open production',
    bottomDivider: false,
    icon: <OpenSecondaryIcon width={16} height={16} />,
  };

  const manageTaskPriority = {
    onClick: () => dispatch(TasksActions.openManageTaskPriorityModal(data?.production_workflow_id)),
    key: 'manage_tasks_priority_label',
    text: 'Manage tasks priority',
    icon: <ArrowSquareRight width={16} height={16} />,
  };

  const taskItemsMap = [copyLink, openTask, openRootProduction, openProduction, manageTaskPriority];

  return <DropdownMenu id="collapse-content-card" items={taskItemsMap} />;
};

export default ActionsMenu;
