import { Stack } from '@mui/material';
import { ChangeEvent, useRef, useState } from 'react';
import DropDownSearchFilter from 'pages/tasks/modules/drop-down-search-filter/drop-down-search-filter';
import DropDownSelectFilter from 'pages/tasks/modules/drop-down-select-filter/drop-down-select-filter';
import DropDownDateFilter from 'pages/tasks/modules/drop-down-date-filter/drop-down-date-filter';
import { FilterSettingEnum } from 'pages/tasks/types';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import s from './floating-filter.module.scss';

const FloatingFilter = (params) => {
  const dispatch = useDispatch();
  const value = useSelector((state: AppState) => state.tasks.filters.queries[params.fieldName]) || '';
  const [searchValue, setSearchValue] = useState(value);
  const anchorElRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const openPopover = () => setIsOpen(true);

  const closePopover = () => setIsOpen(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    dispatch(TasksActions.onFilterChange(params.fieldName, event.target.value));
  };

  if (!params.showSearchInput && !params.showFilterDropdownButton) return null;

  return (
    <>
      <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" gap="12px" ref={anchorElRef}>
        {params.showSearchInput && (
          <input className={s.input} value={searchValue} type={params.inputType || 'text'} onChange={handleInputChange} />
        )}
        {params.showFilterDropdownButton && (
          <button className={s.button} type="button" data-active={isOpen} onClick={openPopover}>
            <div className={s.line} />
            <div className={s.line} />
            <div className={s.line} />
          </button>
        )}
      </Stack>
      {params.useSearchFilter && (
        <DropDownSearchFilter
          isOpen={isOpen}
          anchorEl={anchorElRef.current}
          fieldName={params.fieldName}
          onClose={closePopover}
          fetchOptionsFunction={params.fetchOptionsFunction}
        />
      )}
      {params.useSelectFilter && (
        <DropDownSelectFilter
          menuItems={[
            { text: 'Contains', value: FilterSettingEnum.Contains },
            { text: 'Blank', value: FilterSettingEnum.Blank },
          ]}
          fieldName={params.fieldName}
          isOpen={isOpen}
          anchorEl={anchorElRef.current}
          onClose={closePopover}
        />
      )}
      {params.useDateFilter && (
        <DropDownDateFilter isOpen={isOpen} fieldName={params.fieldName} anchorEl={anchorElRef.current} onClose={closePopover} />
      )}
    </>
  );
};

export default FloatingFilter;
