import { EnvCookies } from 'env-cookies';
import { CurrentUser } from 'services/user.model';
import { UserService } from 'services/user.service';
import { router } from 'index';
import { AuthService } from 'services/auth.service';
import { AppState } from 'redux/store';
import { Tenant, TenantUserStatus } from 'services/tenant.model';
import { TenantService } from 'services/tenant.service';
import { Paths } from 'routes/paths';
import { SettingsActions } from 'pages/settings/settings.controller';
import { getDesiredTenantByUrl, getNonAuthorizedEnvHostname, getSubdomainFromUrl } from 'tenant-helpers';
import { chatWidget } from 'hooks/use-chat-widget';
import { StateController } from '../state-controller';

const authCookiesConfig = {
  expires: 365,
  path: '/',
  secure: true, // Is disabled in env-cookies.ts file class for local environment.
};

export type AuthState = {
  isAuthorized: boolean;
  user: CurrentUser;
  userTenants: Tenant[];
  userTenantId: string;
  isLoading: boolean;
  isReturnUrlRedirecting: boolean;
  isError: boolean;
};

export type AuthTokens = {
  accessToken: string;
  refreshToken: string;
};

const defaultState: AuthState = {
  isAuthorized: false,
  user: null,
  userTenantId: '',
  userTenants: [],
  isLoading: true,
  isReturnUrlRedirecting: false,
  isError: false,
};

const stateController = new StateController<AuthState>('AUTH_STATE', defaultState);

export class Actions {
  public static checkIfUserAlreadyLoggedIn() {
    return async (dispatch) => {
      const auth = JSON.parse(EnvCookies.get('auth') || '{}');
      try {
        if (Object.keys(auth).length !== 0) {
          dispatch(stateController.setState({ isLoading: true }));

          const currentTenantId = EnvCookies.get('tenant');
          const userTenants = await dispatch(Actions.loadUserTenants());

          if (userTenants.length === 1 && !currentTenantId) {
            const userTenantId = userTenants[0]?.id;
            EnvCookies.set('tenant', userTenantId, authCookiesConfig);
          }

          if (!currentTenantId) {
            dispatch(stateController.setState({ isAuthorized: true }));
          } else {
            const currentHostname = window.location.hostname;
            const nonAuthorizedHostname = getNonAuthorizedEnvHostname();

            if (currentHostname !== nonAuthorizedHostname) {
              await dispatch(Actions.loadCurrentUserData());
            }

            dispatch(stateController.setState({ isAuthorized: true }));
          }

          const currentTenant = getDesiredTenantByUrl(window.location.href, userTenants);
          if (!window.location.href.includes('select-company')) {
            await dispatch(SettingsActions.init(true));
          }

          // If a user updates the page on Select Company page, he'll get stuck on Access Denied page without this logic
          const subdomain = getSubdomainFromUrl(window.location.href);

          if (subdomain === getNonAuthorizedEnvHostname()) return;
          // ===============================================================

          if (!currentTenant || currentTenant.user_status === TenantUserStatus.Inactive) {
            router.navigate(Paths.CompanyAccessDenied, { state: { showBackButton: true } });
          }
        }
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static loadCurrentUserData() {
    return async (dispatch) => {
      const user = await UserService.getCurrentUser();
      dispatch(stateController.setState({ user }));
      await dispatch(SettingsActions.init(true));
      return true;
    };
  }

  public static loadUserTenants() {
    return async (dispatch) => {
      const userTenants = await TenantService.getUserTenants();
      dispatch(stateController.setState({ userTenants }));
      return userTenants;
    };
  }

  public static updateUserTenants(tenant: string) {
    return async (dispatch, getState) => {
      const { userTenants } = getState().auth;
      const currentTenantId = EnvCookies.get('tenant');

      const updatedTenants = userTenants.map((tenantObj) => {
        if (tenantObj.id === currentTenantId) {
          return {
            ...tenantObj,
            company_name: tenant,
          };
        }
        return tenantObj;
      });

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          userTenants: updatedTenants,
        })),
      );
    };
  }

  public static changeCurrentUserTenant(tenantId: string) {
    return async (dispatch, getState: () => AppState) => {
      EnvCookies.set('tenant', tenantId, authCookiesConfig);

      const { userTenants } = getState().auth;
      const selectedTenant = userTenants?.find((item) => item.id === tenantId);
      const newHostname = `${selectedTenant.subdomain}.${getNonAuthorizedEnvHostname()}`;
      const newUrl = `${window.location.protocol}//${newHostname}${window.location.pathname}${window.location.search}`;
      window.location.replace(newUrl);
    };
  }

  public static login(username: string, password: string) {
    return async (dispatch) => {
      try {
        let tenantId;

        const authTokens = await AuthService.login({
          username,
          password,
        });
        EnvCookies.set('auth', JSON.stringify(authTokens), authCookiesConfig);

        dispatch(stateController.setState({ isLoading: true }));

        // Load and save user tenants
        const userTenants = await TenantService.getUserTenants();
        const activeTenants = userTenants?.filter((tenant) => tenant.user_status === TenantUserStatus.Active) || [];
        dispatch(stateController.setState({ userTenants }));

        // If user has only one tenant > process to company route
        const userHasOnlyOneTenant = activeTenants.length === 1;
        if (userHasOnlyOneTenant) {
          tenantId = activeTenants[0]?.id;
          EnvCookies.set('tenant', tenantId, authCookiesConfig);

          // await dispatch(Actions.loadCurrentUserData());

          // Trigger to switch routing by changing isAuthorized value;
          // await dispatch(stateController.setState({ isAuthorized: true }));

          // Create destination URL depending on selected tenantId and navigate
          // const selectedTenant = userTenants?.find((item) => item.id === tenantId);
          // const destinationUrl = `${window.location.protocol}//${selectedTenant.subdomain}.${getNonAuthorizedEnvHostname()}`;

          // window.location.replace(destinationUrl);
          return;
        }

        // If user has multiple tenants
        const userHasMultipleTenants = activeTenants.length > 1;
        if (userHasMultipleTenants) {
          router.navigate(Paths.SelectCompany);
          await dispatch(stateController.setState({ isAuthorized: true }));
        }
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static logout() {
    return async (dispatch) => {
      const authTokens: AuthTokens = JSON.parse(EnvCookies.get('auth') || '{}');

      await dispatch(stateController.setState({ isLoading: true }));
      // Use logout API method to delete user session in case we have refresh token stored in cookies
      // If there are no refresh token in cookies, it means that user session is deleted already from other browser tab
      if (authTokens.refreshToken) {
        await AuthService.logout(authTokens.refreshToken);
      }

      router.navigate('/sign-in');
      EnvCookies.remove('auth');
      EnvCookies.remove('tenant');
      EnvCookies.remove('return-url');

      dispatch(SettingsActions.dispose());
      dispatch(stateController.setState(defaultState));
      chatWidget.logout();

      const newUrl = `${window.location.protocol}//${getNonAuthorizedEnvHostname()}/sign-in`;
      window.location.replace(newUrl);
    };
  }

  public static refreshAccessToken() {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));

        const { refreshToken } = JSON.parse(EnvCookies.get('auth') || '{}');
        if (refreshToken) {
          const authTokens = await AuthService.refreshToken(refreshToken);
          EnvCookies.set('auth', JSON.stringify(authTokens), authCookiesConfig);

          await dispatch(stateController.setState({ isAuthorized: true }));

          return authTokens.accessToken;
        }
        return false;
      } catch (error) {
        router.navigate('/sign-in');
        EnvCookies.remove('auth');
        EnvCookies.remove('tenant');
        throw error;
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }
}

export class Selectors {
  public static isPermitted(state: AppState, permission: string, value: string) {
    return state.auth.user?.user_permissions?.[permission] === value;
  }

  public static getUserDepartmentIds(state: AppState) {
    const positionSlots = state.auth.user.user_position_slots;
    const userDepartmentIds = positionSlots.map((i) => i.position_slot.department_id);
    return userDepartmentIds;
  }

  public static isUserActiveInTenant(state: AppState) {
    const currentTenantId = EnvCookies.get('tenant');
    const currentTenant = state.auth.userTenants.find((tenant) => tenant.id === currentTenantId);
    return currentTenant?.user_status === TenantUserStatus.Active;
  }
}

export const reducer = stateController.getReducer();
