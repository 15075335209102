import { ReactNode } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { usePopupState, bindFocus, bindMenu, bindTrigger } from 'material-ui-popup-state/hooks';
import { ExtendButtonBase, IconButton, Menu, MenuItem, MenuItemTypeMap, styled, Tooltip } from '@mui/material';
import s from './dropdown-menu.module.scss';

export enum ActionsModeEnum {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
}

export type DropdownMenuItemFunc = () => {
  key: string;
  render: () => ReactNode;
  onClick: (...params: any) => any;
};
export type DropdownMenuItemObj = {
  key: string;
  onClick: VoidFunction;
  text: string | ReactNode;
  icon?: ReactNode;
  hoverColor?: string;
  defaultColor?: string;
  bottomDivider?: boolean;
  containerClassName?: string;
};
export type DropdownMenuItem = DropdownMenuItemFunc | DropdownMenuItemObj;

type Props = {
  id: string;
  items: DropdownMenuItem[];
  disabled?: boolean;
  buttonIcon?: ReactNode;
  buttonClassName?: string;
  wrapperClassName?: string;
  position?: 'center' | 'left' | 'right';
  renderButton?: (props) => ReactNode;
  disabledItems?: { [key: string]: string };
  ButtonComponent?: React.ComponentType<any>;
};

const DEFAULT_COLOR = '#141414';
const HOVER_COLOR = '#F1F5F9';

export const MenuItemStyled = styled<ExtendButtonBase<MenuItemTypeMap<{ hovercolor?: string; defaultcolor?: string }, 'li'>>>(
  MenuItem,
)(({ defaultcolor, hovercolor }) => {
  return {
    color: defaultcolor || DEFAULT_COLOR,
    '& svg *': {
      stroke: defaultcolor || DEFAULT_COLOR,
    },
    '&:hover': {
      backgroundColor: hovercolor || HOVER_COLOR,
    },
  };
});

function DropdownMenu({
  id,
  items,
  disabled,
  buttonIcon,
  buttonClassName,
  wrapperClassName,
  disabledItems = {},
  position = 'center',
  renderButton,
}: Props) {
  const popupState = usePopupState({ variant: 'dialog', popupId: `actions-${id}` });
  const toggleProps = bindFocus(popupState);

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className={`
        ${s.dropdown_menu}
        ${wrapperClassName}
      `}
      data-disabled={disabled}
    >
      {typeof renderButton === 'function' ? (
        renderButton({
          onFocus: (event) => {
            event.stopPropagation();
            toggleProps.onFocus(event);
          },
          onBlur: (event) => {
            toggleProps.onBlur(event);
          },
          ...bindTrigger(popupState),
        })
      ) : (
        <IconButton
          onFocus={(event) => {
            event.stopPropagation();
            toggleProps.onFocus(event);
          }}
          onBlur={(event) => {
            toggleProps.onBlur(event);
          }}
          className={buttonClassName && buttonClassName}
          onClick={(event) => {
            event.stopPropagation();
          }}
          {...bindTrigger(popupState)}
          size="small"
        >
          {buttonIcon || <MoreVertOutlinedIcon />}
        </IconButton>
      )}
      <Menu
        {...bindMenu(popupState)}
        key={id}
        transformOrigin={{
          vertical: 'top',
          horizontal: position,
        }}
        disableAutoFocusItem
        classes={{ paper: s.menu_container }}
      >
        {items.map((menuItem, index) => {
          const isLastItem = items.length < index + 2;
          return typeof menuItem === 'function' ? (
            <div
              key={menuItem().key}
              onClick={(event) => {
                event.stopPropagation();
                popupState.close();
                menuItem().onClick();
              }}
            >
              {menuItem().render()}
            </div>
          ) : (
            <Tooltip key={menuItem.key} title={disabledItems[menuItem.key] || ''} placement="top-start">
              <div>
                <div key={menuItem.key} className={menuItem.key in disabledItems ? s.disabled : ''}>
                  <MenuItemStyled
                    sx={menuItem.defaultColor && { color: menuItem.defaultColor }}
                    className={`${s.menu_item} ${menuItem.containerClassName || ''}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      popupState.close();
                      menuItem.onClick();
                    }}
                    hovercolor={menuItem.hoverColor || HOVER_COLOR}
                    defaultcolor={menuItem.defaultColor || DEFAULT_COLOR}
                  >
                    {menuItem.icon && <div className={s.icon_container}>{menuItem.icon}</div>}
                    {menuItem.text}
                  </MenuItemStyled>
                  {menuItem.bottomDivider && !isLastItem && <div className={s.divider} />}
                </div>
              </div>
            </Tooltip>
          );
        })}
      </Menu>
    </div>
  );
}

export default DropdownMenu;
