/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
import { ColGroupDef, ValueSetterParams } from 'ag-grid-community';
import { notify } from 'notifications';
import { ManageTaskPriorityModalActions } from 'pages/production/controllers/manage-task-priority.controller';
import { agGridGroupedColumnDefinitions, TASKS_PER_PAGE, valueOptionsSelectedOptions } from 'pages/tasks/constants';
import { splitAndReverse } from 'pages/tasks/mocks';
import { generateRequestBody } from 'pages/tasks/tasks.helpers';
import { TasksFiltersEnum, TasksFiltersT } from 'pages/tasks/types';
import { GetStateFunction } from 'redux/store';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { TaskTableModel } from 'services/task-table.model';
import { TaskTableService } from 'services/task-table.service';
import { AssignmentType } from 'services/workflow-task-template-responsibility.model';
import { StateController } from 'state-controller';
import { TaskTypeEnum } from 'types/common-enums';
import { IdName } from 'types/common-types';
import { PaginateResponse } from 'types/paginate-response';
import { PriorityEnum } from 'types/priority-enums';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';
import { debounce } from 'utils/debounce';

export type TasksState = {
  isLoading: boolean;
  columnOrder: string[];
  filters: TasksFiltersT;
  atLeastOneFilterSelected: boolean;
  tasks: PaginateResponse<TaskTableModel>;
  groupedColumnDefinition: ColGroupDef[];
};

const tasksDefaultState: TasksState = {
  tasks: {
    data: [],
    meta: {
      currentPage: 0,
      lastPage: 0,
      next: 0,
      perPage: 0,
      prev: 0,
      total: 0,
    },
  },
  columnOrder: [],
  isLoading: false,
  groupedColumnDefinition: [],
  atLeastOneFilterSelected: false,
  filters: {
    filters: {
      // Production
      [TasksFiltersEnum.ProductionDeadline]: { value: [null, null] },
      [TasksFiltersEnum.ProductionStatus]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'To Do', id: ProductionStatusEnum.To_Do },
          { name: 'Stopped', id: ProductionStatusEnum.Stopped },
          { name: 'In Progress', id: ProductionStatusEnum.In_Progress },
          { name: 'Done', id: ProductionStatusEnum.Done },
          { name: 'From stock', id: ProductionStatusEnum.From_Stock },
          { name: 'Canceled', id: ProductionStatusEnum.Canceled },
        ],
      },
      [TasksFiltersEnum.RootProductionDeadline]: { value: [null, null] },

      // Task
      [TasksFiltersEnum.IsInQueue]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.IsFailed]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.FailedAt]: { value: [null, null] },
      [TasksFiltersEnum.TaskType]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Workflow', id: TaskTypeEnum.Workflow },
          { name: 'Additional', id: TaskTypeEnum.Additional },
        ],
      },
      [TasksFiltersEnum.TaskStatus]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Reopened', id: TaskStatusEnum.Reopened },
          { name: 'In Progress', id: TaskStatusEnum.In_Progress },
          { name: 'On hold', id: TaskStatusEnum.On_Hold },
          { name: 'To Do', id: TaskStatusEnum.To_Do },
          { name: 'Blocked', id: TaskStatusEnum.Blocked },
          { name: 'Done', id: TaskStatusEnum.Done },
          { name: 'Canceled', id: TaskStatusEnum.Canceled },
        ],
      },
      [TasksFiltersEnum.ReportingPeriod]: { value: [null, null] },
      [TasksFiltersEnum.TaskPriority]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: PriorityEnum.Highest, id: PriorityEnum.Highest },
          { name: PriorityEnum.High, id: PriorityEnum.High },
          { name: PriorityEnum.Medium, id: PriorityEnum.Medium },
          { name: PriorityEnum.Low, id: PriorityEnum.Low },
          { name: PriorityEnum.Lowest, id: PriorityEnum.Lowest },
        ],
      },
      [TasksFiltersEnum.ReasonForFailure]: valueOptionsSelectedOptions,

      // Assignment
      [TasksFiltersEnum.Assignee]: valueOptionsSelectedOptions,
      [TasksFiltersEnum.AssigneeType]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Manual', id: AssignmentType.Manual },
          { name: 'Automatic', id: AssignmentType.Auto },
          { name: 'Self assignment', id: AssignmentType.Self_Assignment },
        ],
      },

      // Warnings
      [TasksFiltersEnum.AssigneeRequired]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.TimeLimitExceeded]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
    },
    queries: {
      // Product
      [TasksFiltersEnum.ProductName]: '',
      [TasksFiltersEnum.ProductVersion]: 0,
      [TasksFiltersEnum.ProductVariant]: '',
      [TasksFiltersEnum.RootProductName]: '',
      [TasksFiltersEnum.RootProductVersion]: 0,
      [TasksFiltersEnum.RootProductVariant]: '',
      [TasksFiltersEnum.ProductConfiguration]: '',
      [TasksFiltersEnum.RootProductConfiguration]: '',

      // Production
      [TasksFiltersEnum.ProductionKey]: '',
      [TasksFiltersEnum.RootProductionKey]: '',

      // Order
      [TasksFiltersEnum.Client]: '',
      [TasksFiltersEnum.OrderKey]: '',
      [TasksFiltersEnum.PrimaryClient]: '',
      [TasksFiltersEnum.ExternalOrderNumber]: '',
      [TasksFiltersEnum.MarketPlaceOrderNumber]: '',

      // Task
      [TasksFiltersEnum.TaskName]: '',
      [TasksFiltersEnum.TaskKey]: '',
      [TasksFiltersEnum.ReasonForFailure]: '',

      // Assignment
      [TasksFiltersEnum.AssigneePosition]: '',
      [TasksFiltersEnum.AssigneeDepartment]: '',
    },
  },
};

const stateController = new StateController<TasksState>('TASKS', tasksDefaultState);

export class TasksActions {
  static initTasksPage() {
    return (dispatch) => {
      dispatch(TasksActions.getTasksByFilters());
    };
  }

  static getTasksByFilters(skip = 0, take = TASKS_PER_PAGE) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));

        const { filters } = getState().tasks;

        const body = generateRequestBody(filters);

        const response = await TaskTableService.getAllTask(body, { skip, take });

        // fetch data from backend and mark columns not to show with hide: true
        const modified = agGridGroupedColumnDefinitions.map((group) => ({
          ...group,
          children: group.children.map((column) => ({
            ...column,
            // Unfortunately this is how updates should happen. If you try to use onCellValueChanged
            // Ag-Grid will be mutating your original state and not via dispatch but like plane object mutations
            valueSetter: (params: ValueSetterParams<TaskTableModel>) =>
              dispatch(TasksActions.updateTask(params.data.id, { [params.colDef.field]: params.newValue })),
            // filterValueGetter: (params: ValueGetterParams) => console.log(params),
          })),
        }));

        dispatch(
          stateController.setState({
            tasks: response,
            // Will be fetched from backend
            columnOrder: splitAndReverse(Object.values(TasksFiltersEnum)),
            groupedColumnDefinition: modified,
          }),
        );
      } catch (error) {
        notify.error(error.message);
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  // static onFilterChange({ values, disableFetch, extendExistingValue, showLoadingEffect }: OnFilterChangeArgs) {
  //   return (dispatch: DispatchT) => {
  //     dispatch(
  //       stateController.setState(prevState => ({
  //         ...prevState,
  //         isLoading: showLoadingEffect === undefined ? prevState.isLoading : showLoadingEffect,
  //         groupBy: values.to_assign ? GroupByEnum.Status : prevState.groupBy,
  //         filters: (Object.keys(values) as [keyof Partial<{ [key in TasksFilterEnum]: FilterDataT['value'] }>]).reduce(
  //           (acc, item) => {
  //             const currentValue = values[item];
  //             const previousFilter = prevState.filters[item];

  //             // The "undefined" comparison is needed to allow empty string in search filter
  //             if (currentValue === undefined) {
  //               return { ...acc, [item]: previousFilter };
  //             }

  //             if (
  //               extendExistingValue ||
  //               typeof currentValue === 'boolean' ||
  //               (Array.isArray(currentValue) && typeof currentValue[0] === 'object')
  //             ) {
  //               return { ...acc, [item]: { ...previousFilter, value: currentValue } };
  //             }

  //             if (isStringValuesInArray(currentValue)) {
  //               return {
  //                 ...acc,
  //                 [item]: {
  //                   ...previousFilter,
  //                   value: previousFilter.options?.filter(option => currentValue?.includes(option.id)),
  //                 },
  //               };
  //             }

  //             if (typeof currentValue === 'string') {
  //               return { ...acc, [item]: { value: currentValue } };
  //             }

  //             const previousFilterValue = previousFilter.value;

  //             if (!Array.isArray(currentValue) && isIdNameValuesInArray(previousFilterValue)) {
  //               return {
  //                 ...acc,
  //                 [item]: {
  //                   ...previousFilter,
  //                   value: previousFilterValue.some(filter => filter.id === currentValue.id)
  //                     ? previousFilterValue.filter(filter => filter.id !== currentValue.id)
  //                     : [...previousFilterValue, currentValue],
  //                 },
  //               };
  //             }

  //             return { ...acc, [item]: prevState.filters[item] };
  //           },
  //           { ...prevState.filters },
  //         ),
  //       })),
  //     );

  //     if (disableFetch) return;

  //     debounce(async () => {
  //       await dispatch(TasksActions.getTasksByFilters({ skip: 0 }));

  //       if (showLoadingEffect) dispatch(stateController.setState({ isLoading: false }));
  //     }, 500);
  //   };
  // }

  // ! There is created function above ☝️. This is how it's implemented on mobile with strong types. So if you need to a reference on how to achieve smth look it up there
  static setFilters(values: Partial<TasksFiltersT>, extendExistingValue?: boolean) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          filters: (Object.keys(values) as [keyof Partial<TasksFiltersT>]).reduce((acc, item) => {
            const currentValue = values[item];
            const previousFilter = prevState.filters[item];

            // The "undefined" comparison is needed to allow empty string in search filter
            if (currentValue === undefined) {
              return { ...acc, [item]: previousFilter };
            }

            if (
              extendExistingValue ||
              typeof currentValue === 'boolean' ||
              (Array.isArray(currentValue) && typeof currentValue[0] === 'object')
            ) {
              return { ...acc, [item]: { ...previousFilter, value: currentValue } };
            }

            return { ...acc, [item]: { value: currentValue } };
          }, prevState.filters),
        })),
      );
    };
  }

  static updateTask(taskId: string, value: Partial<TaskTableModel>) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: prev.tasks.data.map((task) => (task.id === taskId ? { ...task, ...value } : task)),
        })),
      );
    };
  }

  public static copyTaskLink(taskId: string) {
    return async () => {
      const { origin } = window.location;
      const link = `${origin}/task/${taskId}`;
      await navigator.clipboard.writeText(link);
      notify.success('The link is copied');
    };
  }

  public static onFilterChange(fieldName: TasksFiltersEnum, value: any, id?: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().tasks;

      const isFilterField = filters.filters.hasOwnProperty(fieldName);
      const isQueryField = filters.queries.hasOwnProperty(fieldName);

      const updatedFilters = { ...filters };

      if (isFilterField) {
        const currentSelectedOptions = filters.filters[fieldName].selectedOptions || [];

        const updatedSelectedOptions = currentSelectedOptions.includes(id)
          ? currentSelectedOptions.filter((option) => option !== id)
          : [...currentSelectedOptions, id];

        updatedFilters.filters = {
          ...filters.filters,
          [fieldName]: {
            ...filters.filters[fieldName],
            value,
            selectedOptions: updatedSelectedOptions,
          },
        };
      }

      if (isQueryField) {
        updatedFilters.queries = {
          ...filters.queries,
          [fieldName]: value,
        };
      }

      if (isFilterField && isQueryField) {
        updatedFilters.filters[fieldName].value = value;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          filters: updatedFilters,
        })),
      );

      debounce(() => {
        dispatch(TasksActions.getTasksByFilters());
        dispatch(TasksActions.checkIfAtLeastOneFilterSelected());
      }, 500);
    };
  }

  private static hasSelectedValues(filterValue: any) {
    if (Array.isArray(filterValue.value)) {
      return filterValue.value.some((value) => value !== null);
    }

    return filterValue.selectedOptions && filterValue.selectedOptions.length > 0;
  }

  public static checkIfAtLeastOneFilterSelected() {
    return (dispatch, getState: GetStateFunction) => {
      const { filters, queries } = getState().tasks.filters;

      for (const filterValue of Object.values(filters)) {
        if (TasksActions.hasSelectedValues(filterValue)) {
          dispatch(stateController.setState({ atLeastOneFilterSelected: true }));

          return;
        }
      }

      for (const queryValue of Object.values(queries)) {
        if (queryValue !== '' && queryValue !== 0) {
          dispatch(stateController.setState({ atLeastOneFilterSelected: true }));

          return;
        }
      }

      dispatch(stateController.setState({ atLeastOneFilterSelected: false }));
    };
  }

  public static setFilterOptions(fieldName: TasksFiltersEnum, options: IdName[]) {
    return (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().tasks;

      const currentFilter = filters?.filters[fieldName] || {};

      dispatch(
        stateController.setState({
          filters: {
            ...filters,
            filters: {
              ...filters.filters,
              [fieldName]: {
                ...currentFilter,
                options,
              },
            },
          },
        }),
      );
    };
  }

  public static openTaskInNewTab(taskId: string) {
    return () => {
      const { origin } = window.location;
      const link = `${origin}/task/${taskId}`;
      window.open(link, '_blank');
    };
  }

  public static openRootOrMainProductionInNewTab(productionId: string | null) {
    return () => {
      if (!productionId) return;
      const { origin } = window.location;
      const link = `${origin}/production-workflow/${productionId}`;
      window.open(link, '_blank');
    };
  }

  public static openManageTaskPriorityModal(productionId: string) {
    return async (dispatch) => {
      try {
        const production = await ProductionWorkflowService.getProductionWorkflowInfo(productionId);
        if (production) {
          dispatch(ManageTaskPriorityModalActions.openModal({ production }));
        }
      } catch (error) {
        notify.error(error.message);
      }
    };
  }
}

export class TasksSelectors {}

export const tasksReducer = stateController.getReducer();
