import { ProductCategoryWithPath } from 'services/product-categories.model';
import { ProductData, ProductMeta, ProductVersion, UpdateProductMeta } from 'services/products.model';
import { ProductsService } from 'services/products.service';
import { StateController } from 'state-controller';
import { ProductType } from 'services/product-type.model';
import { ProductTypeService } from 'services/product-type.service';
import { ProductVendorService } from 'services/product-vendor.service';
import { ProductVendor } from 'services/product-vendor.model';
import { AccessLevel, Permission } from 'services/permission.model';
import { v4 as uuidv4 } from 'uuid';
// import { PAGE_SIZE } from 'components/text-editable-select/text-editable-select';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { MODALS } from 'modules/root-modals/modals';
import { AppState, GetStateFunction } from 'redux/store';
import { ProductTagsService } from 'services/product-tags.service';
import { ManageProductTagsData, Tag } from 'services/product-tags.model';
import { Actions as ProductConfigurationsActions } from 'pages/product-flow/pages/product/controllers/product-configurations.controller';
import { Actions as ProductVersionsActions } from 'pages/product-flow/pages/product/controllers/product-version.controller';
import { Actions as ProductDescriptionsActions } from 'pages/product-flow/pages/product/controllers/product-description.controller';
import { Actions as ProductFileActions } from 'pages/product-flow/pages/product/controllers/product-file.controller';
import { ProductMediaActions } from 'pages/product-flow/pages/product/controllers/product-media.controller';
import { Actions as ProductWorkflowActions } from 'pages/product-flow/pages/product/controllers/product-workflow.controller';
import { Actions as ProductParameterActions } from 'pages/product-flow/pages/product/controllers/product-parameter.controller';
import { Actions as ProductVariantActions } from 'pages/product-flow/pages/product/controllers/product-variant.controller';
import { Actions as ProductWorkFlowLayoutActions } from 'pages/product-flow/product-flow-layout/product-flow-layout.controller';
import { IdNameType } from 'types/common-types';
import { debounce } from 'utils/debounce';
import { versionName } from 'utils/version-name';
import FireMinusIcon from 'icons/fire-minus';
import { ActiveTickIcon } from 'icons/active-tick';
import { notify } from 'notifications';
import { FilledWarningIcon } from 'icons/filled-warning';
import { Actions as ProductConfigurationController } from './product-configurations.controller';
import s from '../product.module.scss';

type NewType = {
  isLoading: boolean;
  isProcessing: boolean;
  isSaving: boolean;
  isPreviewMode: boolean;
  id: string;
  category_path: Array<IdNameType>;
  product_draft_id: string;
  categories: ProductCategoryWithPath[];
  types: ProductType[];
  vendors: ProductVendor[];
  tags: Tag[];
  published_products: ProductVersion[];
  productManageTagsModal: {
    isOpen: boolean;
    isSaving: boolean;
  };
};

export enum DataArrayName {
  Type = 'types',
  Vendor = 'vendors',
  Category = 'categories',
}

export type ProductState = NewType & Omit<ProductData, 'published_products'>;
export type Workflow = {
  id: string;
  name: string;
  tasksCount: number;
  insetProductsCount: number;
};

const defaultState: ProductState = {
  isLoading: true,
  isSaving: false,
  isProcessing: false,
  isPreviewMode: false,

  id: null,
  category_path: [],
  product_draft_id: null,

  modified_at: '',
  modified_by: '',
  configurations: 0,
  variants: 0,
  workFlows: 0,
  is_active: false,

  product_meta: {
    id: '',
    name: '',
    product_type: { id: '', name: '' },
    product_vendor: { id: '', name: '' },
    product_category: { id: '', name: '' },
    product_tag_relations: [{ id: '', tag: { id: '', name: '' } }],
  },

  tags: [],
  types: [],
  vendors: [],
  categories: [],

  version: '',
  published_products: [],

  productManageTagsModal: {
    isOpen: false,
    isSaving: false,
  },
};

const stateController = new StateController<ProductState>('PRODUCT_STATE', defaultState);

export class Actions {
  public static setIsProcessing(value: boolean) {
    return (dispatch) => {
      dispatch(stateController.setState({ isProcessing: value }));
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
      dispatch(ProductVersionsActions.disposeState());
      dispatch(ProductConfigurationsActions.disposeState());
      dispatch(ProductDescriptionsActions.disposeState());
      dispatch(ProductFileActions.disposeState());
      dispatch(ProductMediaActions.disposeState());
      dispatch(ProductWorkflowActions.disposeState());
      dispatch(ProductParameterActions.disposeState());
      dispatch(ProductVariantActions.disposeState());
    };
  }

  // ====== PRODUCT MAIN INFO ================================================================================================================

  public static loadMisc() {
    return async (dispatch) => {
      const tags = await ProductTagsService.getAllTags();

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          tags,
        })),
      );
    };
  }

  public static initProduct(id: string, configurationId?: string) {
    return async (dispatch) => {
      try {
        dispatch(ProductWorkFlowLayoutActions.setDefaultProductRoad());
        dispatch(stateController.setState({ isLoading: true }));

        await dispatch(Actions.loadMisc());

        const { published_products, version, modified_at, ...product } = await ProductsService.getProductDetailedInfoById(id);

        dispatch(ProductConfigurationsActions.setConfigurations(product.product_configurations));
        dispatch(ProductWorkFlowLayoutActions.setProductRoad(product.id, product.product_meta.name));

        const draftVersion: ProductVersion = modified_at
          ? {
              id: product.id,
              is_main_version: false,
              is_published: false,
              published_at: modified_at,
              version: versionName(version),
              is_active: true,
              in_production_count: 0,
            }
          : null;

        const mappedArr = published_products
          .map((item) => ({
            ...item,
            version: versionName(item.version),
          }))
          .reverse();

        await dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            ...product,
            version,
            modified_at,
            published_products,
          })),
        );
        const productConfigurationsCopy = JSON.parse(JSON.stringify(product.product_configurations));
        const defaultConfig = productConfigurationsCopy.sort((a, b) => a.order - b.order)[0];
        dispatch(ProductVersionsActions.init(draftVersion ? [draftVersion, ...mappedArr] : mappedArr));
        dispatch(Actions.initConfigurationTab(configurationId || defaultConfig.id));
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static initConfigurationTab(id: string) {
    return async (dispatch, getState: GetStateFunction) => {
      dispatch(ProductDescriptionsActions.setInitLoading());
      dispatch(ProductFileActions.setInitLoading());
      dispatch(ProductMediaActions.setInitLoading());
      dispatch(ProductWorkflowActions.setInitLoading());
      dispatch(ProductParameterActions.setInitLoading());
      dispatch(ProductVariantActions.setInitLoading());

      await dispatch(ProductConfigurationsActions.init(id));

      const { active_configuration } = getState().product.product_configurations;

      dispatch(ProductDescriptionsActions.init(active_configuration.description, active_configuration.sku));
      dispatch(ProductFileActions.init(active_configuration.product_configuration_files));
      dispatch(ProductMediaActions.init(active_configuration.product_configuration_photos));
      dispatch(ProductWorkflowActions.init(active_configuration.workflowTemplates));
      dispatch(ProductParameterActions.init(active_configuration.product_configuration_parameters));
      dispatch(ProductVariantActions.init(active_configuration.product_variants));
    };
  }

  public static openPublishProductModal() {
    return (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { product_meta, version, id } = getState().product.product_root;

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Publish version',
            text: (
              <span style={{ fontSize: '14px', fontWeight: '400' }}>
                You publish <strong>{versionName(version)}</strong> of <strong>{product_meta.name}</strong> product. <br /> This
                published version will be used as the default product version for production process.
              </span>
            ),
            icon: <FilledWarningIcon />,
            backgroundColor: '#FCE8C0',
            withCloseButton: false,
            actionButtonColor: 'primary',
            actionText: 'Publish',
            action: () => dispatch(Actions.publishProduct(id)),
          },
        }),
      );
    };
  }

  public static publishProduct(productId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { version } = getState().product.product_root;
      const data = { id: productId };
      await ProductsService.publishProduct(data);
      dispatch(Actions.initProduct(productId));
      notify.success(`${versionName(version)} successfully published`);
    };
  }

  public static updateProductModifiedAt() {
    return async (dispatch, getState: () => AppState) => {
      const { modified_at, id } = getState().product.product_root;
      await ProductsService.updateProduct(id);

      if (modified_at === null) {
        dispatch(Actions.initProduct(id));
      }
    };
  }

  public static updateProduct(dbData: Partial<UpdateProductMeta>, stateData: Partial<ProductMeta>) {
    return async (dispatch, getState: () => AppState) => {
      const { product_meta } = getState().product.product_root;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_meta: { ...prev.product_meta, ...stateData },
            isProcessing: true,
          })),
        );

        await ProductsService.updateProductMeta(product_meta.id, dbData);
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, product_meta })));
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static toggleIsPreviewMode() {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, isPreviewMode: !prev.isPreviewMode })));
    };
  }

  public static onProductIsActiveChange() {
    return async (dispatch, getState: GetStateFunction) => {
      const { id, is_active } = getState().product.product_root;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            is_active: !is_active,
          })),
        );

        debounce(async () => {
          await ProductsService.changeIsActive(id, { is_active: !is_active });
        }, 500);

        if (is_active) {
          notify.success('Access was successfully closed');
        } else {
          notify.success('Access was successfully opened');
        }
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, is_active: !is_active })));
      }
    };
  }

  public static openToggleManagementModal() {
    return (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { name } = getState().product.product_root.product_meta;
      const { product_id: productId } = getState().product.product_configurations.active_configuration;
      const { version } = getState().product.product_root;
      const { is_active } = getState().product.product_root;

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: is_active ? 'Inactivate product?' : 'Activate product?',
            text: is_active ? (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Closing access to <strong>{name}</strong> will prevent this product, including all its versions, configurations,
                  and workflows, from being selected for production or used as a component in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      dispatch(ProductConfigurationController.openRelationProductModal(productId, name, version));
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to inactivate <strong>{name}</strong>?
                </span>
              </div>
            ) : (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Opening access to <strong>{name}</strong> will allow this product to be selected for production or used in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      dispatch(ProductConfigurationController.openRelationProductModal(productId, name, version));
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to activate <strong>{name}</strong> of the product?
                </span>
              </div>
            ),
            icon: is_active ? <FireMinusIcon /> : <ActiveTickIcon />,
            backgroundColor: is_active ? '#FFCECE' : '#BCF4DE',
            withCloseButton: false,
            actionButtonColor: is_active ? 'error' : 'primary',
            actionText: is_active ? 'Inactivate' : 'Activate',
            action: () => dispatch(Actions.onProductIsActiveChange()),
          },
        }),
      );
    };
  }

  public static onProductNameChange(name: string) {
    return (dispatch) => {
      dispatch(Actions.updateProduct({ name }, { name }));
    };
  }

  public static onCategoryChange(id: string, name: string) {
    return async (dispatch) => dispatch(Actions.updateProduct({ product_category_id: id }, { product_category: { id, name } }));
  }

  public static onTypeChange(id: string, name: string) {
    return (dispatch) => dispatch(Actions.updateProduct({ product_type_id: id }, { product_type: { id, name } }));
  }

  public static onTypeCreateOption(name: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const currentTypeId = getState().product.product_root.product_meta.product_type?.id;
      const newTypeTemporaryId = uuidv4();

      try {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            types: [...prevState.types, { id: newTypeTemporaryId, name }],
            product_type_id: newTypeTemporaryId,
          })),
        );

        const { id: realDbId } = await ProductTypeService.createProductType(name);

        // after successfully created new vendor replace temporaryId with real id
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            product_type_id: realDbId,
            types: prevState.types.map((type) => {
              if (type.id === newTypeTemporaryId) {
                return {
                  ...type,
                  id: realDbId,
                };
              }
              return type;
            }),
          })),
        );
        await dispatch(Actions.onTypeChange(realDbId, name));
      } catch (err) {
        // rollback changes if something went wrong
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            types: prevState.types.filter((type) => type.id !== newTypeTemporaryId),
            product_type_id: currentTypeId,
          })),
        );
      }
    };
  }

  public static onVendorChange(id: string, name: string) {
    return (dispatch) => dispatch(Actions.updateProduct({ product_vendor_id: id }, { product_vendor: { id, name } }));
  }

  public static onVendorCreateOption(text: string) {
    return async (dispatch, getState: () => AppState) => {
      const currentVendorId = getState().product.product_root.product_meta.product_vendor?.id;
      const newVendorTemporaryId = uuidv4();

      try {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            vendors: [...prevState.vendors, { id: newVendorTemporaryId, name: text }],
            product_vendor_id: newVendorTemporaryId,
          })),
        );

        const { id: realDbId } = await ProductVendorService.create(text);

        // after successfully created new vendor replace temporaryId with real id
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            product_vendor_id: realDbId,
            vendors: prevState.vendors.map((vendor) => {
              if (vendor.id === newVendorTemporaryId) {
                return {
                  ...vendor,
                  id: realDbId,
                };
              }
              return vendor;
            }),
          })),
        );
        await dispatch(Actions.onVendorChange(realDbId, text));
      } catch (err) {
        // rollback changes if something went wrong
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            vendors: prevState.vendors.filter((vendor) => vendor.id !== newVendorTemporaryId),
            product_vendor_id: currentVendorId,
          })),
        );
      }
    };
  }

  public static updateDataArrays(array: DataArrayName, value: ProductCategoryWithPath[] | ProductType[] | ProductVendor[]) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          [array]: [...value],
        })),
      );
    };
  }

  // ====== PRODUCT TAGS =====================================================================================================================

  public static manageProductTags(productTags: Tag[]) {
    return async (dispatch, getState: () => AppState) => {
      const { id } = getState().product.product_root.product_meta;
      try {
        dispatch(stateController.setState({ isSaving: true }));

        const sortedTags = productTags.map((item) => {
          if (item.id.startsWith('new')) {
            return { name: item.name };
          }
          return item;
        });

        const data: ManageProductTagsData = {
          productMetaId: id,
          tags: sortedTags,
        };

        const productTagRelations = await ProductTagsService.manageProductTags(data);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_meta: {
              ...prev.product_meta,
              product_tag_relations: productTagRelations,
            },
          })),
        );
        dispatch(Actions.closeModal('productManageTagsModal'));
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_meta: { ...prev.product_meta, product_tag_relations: productTags },
          })),
        );
      } finally {
        dispatch(stateController.setState({ isSaving: false }));
      }
    };
  }

  public static openTagsManagementModal() {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          productManageTagsModal: {
            ...prevState.productManageTagsModal,
            isOpen: true,
          },
        })),
      );
    };
  }

  public static closeModal(modalName: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          [modalName]: defaultState[modalName],
        })),
      );
    };
  }

  public static deleteProductTagRelation(id: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const currentProductTagsRelations = getState().product.product_root.product_meta.product_tag_relations;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_meta: {
              ...prev.product_meta,
              product_tag_relations: prev.product_meta.product_tag_relations.filter((item) => item.id !== id),
            },
          })),
        );
        await ProductTagsService.deleteProductTagRelation(id);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_meta: { ...prev.product_meta, product_tag_relations: currentProductTagsRelations },
          })),
        );
      }
    };
  }
}

export class Selectors {
  public static product(state: AppState) {
    return state.product.product_root;
  }

  public static getIsSourceActive(state: AppState): boolean {
    return state.product.product_root.is_active;
  }
}

export const reducer = stateController.getReducer();
