// eslint-disable-next-line no-restricted-imports
import { DateRange } from '@mui/lab';
import { AssignmentType } from 'services/workflow-task-template-responsibility.model';
import { TaskTypeEnum } from 'types/common-enums';
import { IdName, MetaT } from 'types/common-types';
import { PriorityEnum } from 'types/priority-enums';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';
import { Dayjs } from 'dayjs';
import { PopoverProps } from '@mui/material';

export enum TasksFiltersEnum {
  // Product
  RootProductName = 'root_product_name',
  RootProductVersion = 'root_product_version',
  RootProductVariant = 'root_product_variant',
  RootProductConfiguration = 'root_product_configuration',
  ProductName = 'product_name',
  ProductVersion = 'product_version',
  ProductVariant = 'product_variant',
  ProductConfiguration = 'product_configuration',

  // Production
  ProductionKey = 'production_key',
  ProductionStatus = 'production_status',
  RootProductionKey = 'root_production_key',
  ProductionDeadline = 'production_deadline',
  RootProductionDeadline = 'root_production_deadline',

  // Order
  Client = 'client',
  OrderKey = 'order_key',
  PrimaryClient = 'primary_client',
  ExternalOrderNumber = 'external_order_number',
  MarketPlaceOrderNumber = 'market_place_order_number',

  // Task Details
  TaskKey = 'task_key',
  IsInQueue = 'is_in_queue',
  IsFailed = 'is_failed',
  FailedAt = 'failed_at',
  TaskType = 'task_type',
  TaskName = 'task_name',
  TaskStatus = 'task_status',
  TaskPriority = 'task_priority',
  ReportingPeriod = 'reporting_period',
  ReasonForFailure = 'reason_for_failure',

  // Assignment Details
  Assignee = 'assignee',
  AssigneeType = 'assignee_type',
  AssigneePosition = 'assignee_position',
  AssigneeDepartment = 'assignee_department',

  // Warnings
  AssigneeRequired = 'assignee_required',
  TimeLimitExceeded = 'time_limit_exceeded',
}

export enum FilterSettingEnum {
  Contains = 'contains',
  Blank = 'blank',
}

export type TasksFiltersDataT = {
  meta?: MetaT;
  value: number | string | IdName | IdName[];
  options?: IdName[];
  selectedOptions?: IdName[];
  filterSetting?: FilterSettingEnum;
};

export type DropDownProps = {
  isOpen: boolean;
  anchorEl: PopoverProps['anchorEl'];
  onClose: PopoverProps['onClose'];
};

export type ValueOptionsSelectedOptionsT = { value: string; options: IdName[]; selectedOptions: IdName[] };

export type ValueOptionsSelectedOptionsWithSettingT = ValueOptionsSelectedOptionsT & { filterSetting: FilterSettingEnum };

export type TasksFiltersT = {
  filters: {
    // Production
    [TasksFiltersEnum.ProductionDeadline]: { value: DateRange<Dayjs> };
    [TasksFiltersEnum.RootProductionDeadline]: { value: DateRange<Dayjs> };
    [TasksFiltersEnum.ProductionStatus]: {
      value: string;
      options: IdName[];
      selectedOptions: ProductionStatusEnum[];
    };

    // Task
    [TasksFiltersEnum.IsInQueue]: { value: string; options: IdName[]; selectedOptions: string[] };
    [TasksFiltersEnum.IsFailed]: { value: string; options: IdName[]; selectedOptions: string[] };
    [TasksFiltersEnum.FailedAt]: { value: DateRange<Dayjs> };
    [TasksFiltersEnum.ReportingPeriod]: { value: DateRange<Dayjs> };
    [TasksFiltersEnum.ReasonForFailure]: ValueOptionsSelectedOptionsT;
    [TasksFiltersEnum.TaskType]: { value: string; options: IdName[]; selectedOptions: TaskTypeEnum[] };
    [TasksFiltersEnum.TaskPriority]: { value: string; options: IdName[]; selectedOptions: PriorityEnum[] };
    [TasksFiltersEnum.TaskStatus]: { value: string; options: IdName[]; selectedOptions: TaskStatusEnum[] };

    // Assignment
    [TasksFiltersEnum.Assignee]: { value: string; options: IdName[]; selectedOptions: string[] };
    [TasksFiltersEnum.AssigneeType]: { value: string; options: IdName[]; selectedOptions: AssignmentType[] };

    // Warnings
    [TasksFiltersEnum.AssigneeRequired]: { value: string; options: IdName[]; selectedOptions: string[] };
    [TasksFiltersEnum.TimeLimitExceeded]: { value: string; options: IdName[]; selectedOptions: string[] };
  };
  queries: {
    // Product
    [TasksFiltersEnum.ProductName]: string;
    [TasksFiltersEnum.ProductVersion]: number;
    [TasksFiltersEnum.ProductVariant]: string;
    [TasksFiltersEnum.RootProductName]: string;
    [TasksFiltersEnum.RootProductVersion]: number;
    [TasksFiltersEnum.RootProductVariant]: string;
    [TasksFiltersEnum.ProductConfiguration]: string;
    [TasksFiltersEnum.RootProductConfiguration]: string;

    // Production
    [TasksFiltersEnum.ProductionKey]: string;
    [TasksFiltersEnum.RootProductionKey]: string;

    // Order
    [TasksFiltersEnum.Client]: string;
    [TasksFiltersEnum.OrderKey]: string;
    [TasksFiltersEnum.PrimaryClient]: string;
    [TasksFiltersEnum.ExternalOrderNumber]: string;
    [TasksFiltersEnum.MarketPlaceOrderNumber]: string;

    // Task
    [TasksFiltersEnum.TaskName]: string;
    [TasksFiltersEnum.TaskKey]: string;
    [TasksFiltersEnum.ReasonForFailure]: string;

    // Assignment
    [TasksFiltersEnum.AssigneePosition]: string;
    [TasksFiltersEnum.AssigneeDepartment]: string;
  };
};
