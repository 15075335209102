import { ColDef, ColGroupDef, GridState } from 'ag-grid-community';
import FloatingFilter from 'pages/tasks/modules/floating-filter/floating-filter';
import { FilterSettingEnum, TasksFiltersEnum } from 'pages/tasks/types';
import { TaskTableModel } from 'services/task-table.model';
import ActionsMenu from 'pages/tasks/components/actions-menu/actions-menu';
import { ProductionTaskService } from 'services/production-task.service';
import { UserService } from 'services/user.service';

export const agGridDefaultColumnDefinitions: ColDef = {
  hide: false,
  editable: false,
  enableCellChangeFlash: false,
  filter: true,
  floatingFilter: true,
  floatingFilterComponentParams: {
    showSearchInput: true,
  },
  suppressFloatingFilterButton: true,
  suppressHeaderFilterButton: true,
  floatingFilterComponent: FloatingFilter,
};

export const agGridInitialState: GridState = {
  // columnOrder: { orderedColIds: [] },
  sideBar: {
    visible: true,
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
      },
    ],
    openToolPanel: '',
    position: 'right',
  },
};

export const agGridGroupedColumnDefinitions: ColGroupDef<TaskTableModel>[] = [
  {
    headerName: 'Product',
    children: [
      {
        field: 'product_name',
        headerName: 'Product name',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ProductName },
      },
      {
        field: 'product_version',
        headerName: 'Product version',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ProductVersion, inputType: 'number' },
      },
      {
        field: 'product_variant',
        headerName: 'Product variant',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ProductVariant },
      },
      {
        field: 'product_configuration',
        headerName: 'Product configuration',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ProductConfiguration },
      },
      {
        field: 'root_product_name',
        headerName: 'Root product name',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.RootProductName },
      },
      {
        field: 'root_product_version',
        headerName: 'Root product version',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.RootProductVersion, inputType: 'number' },
      },
      {
        field: 'root_product_variant',
        headerName: 'Root product variant',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.RootProductVariant },
      },
      {
        field: 'root_product_configuration',
        headerName: 'Root product configuration',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.RootProductConfiguration },
      },
    ],
  },
  {
    headerName: 'Production',
    children: [
      {
        field: 'production_key',
        headerName: 'Production key',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ProductionKey },
      },
      {
        field: 'production_status',
        headerName: 'Production status',
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useSearchFilter: true,
          fieldName: TasksFiltersEnum.ProductionStatus,
        },
      },
      {
        field: 'root_production_key',
        headerName: 'Root production key',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.RootProductionKey },
      },
      {
        field: 'production_deadline',
        headerName: 'Production deadline',
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useDateFilter: true,
          fieldName: TasksFiltersEnum.ProductionDeadline,
        },
      },
      {
        field: 'production_progress',
        headerName: 'Production progress',
        floatingFilterComponentParams: { showSearchInput: false },
      },
      {
        field: 'root_production_deadline',
        headerName: 'Root production deadline',
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useDateFilter: true,
          fieldName: TasksFiltersEnum.RootProductionDeadline,
        },
      },
    ],
  },
  {
    headerName: 'Order',
    children: [
      {
        field: 'client',
        headerName: 'Client',
        floatingFilterComponentParams: {
          useSelectFilter: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.Client,
        },
      },
      {
        field: 'order_key',
        headerName: 'Order key',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.OrderKey },
      },
      {
        field: 'primary_client',
        headerName: 'Primary client',
        floatingFilterComponentParams: {
          useSelectFilter: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.PrimaryClient,
        },
      },
      {
        field: 'external_order_number',
        headerName: 'External order number',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.ExternalOrderNumber },
      },
      {
        field: 'market_place_order_number',
        headerName: 'Market place order number',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.MarketPlaceOrderNumber },
      },
    ],
  },
  {
    headerName: 'Task details',
    children: [
      {
        field: 'task_key',
        headerName: 'Task key',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.TaskKey },
      },
      {
        field: 'task_name',
        headerName: 'Task name',
        floatingFilterComponentParams: { fieldName: TasksFiltersEnum.TaskName },
      },
      {
        field: 'failed_at',
        headerName: 'Failed at',
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useDateFilter: true,
          fieldName: TasksFiltersEnum.FailedAt,
        },
      },
      {
        field: 'is_failed',
        headerName: 'Is failed',
        floatingFilterComponentParams: {
          useSearchFilter: true,
          showSearchInput: false,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.IsFailed,
        },
      },
      {
        field: 'task_type',
        headerName: 'Task type',
        floatingFilterComponentParams: {
          showSearchInput: false,
          useSearchFilter: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.TaskType,
        },
      },
      {
        field: 'in_queue',
        headerName: 'Is “In queue”',
        floatingFilterComponentParams: {
          showSearchInput: false,
          useSearchFilter: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.IsInQueue,
        },
      },
      {
        field: 'total_bonus',
        headerName: 'Total bonus',
        floatingFilterComponentParams: { showSearchInput: false },
      },
      {
        field: 'task_status',
        headerName: 'Task status',
        floatingFilterComponentParams: {
          useSearchFilter: true,
          showSearchInput: false,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.TaskStatus,
        },
      },
      { field: 'basic_reward', headerName: 'Basic reward', floatingFilterComponentParams: { showSearchInput: false } },
      {
        field: 'task_priority',
        headerName: 'Task priority',
        floatingFilterComponentParams: {
          useSearchFilter: true,
          showSearchInput: false,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.TaskPriority,
        },
      },
      {
        field: 'reporting_period',
        headerName: 'Reporting period',
        hide: true,
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useDateFilter: true,
          fieldName: TasksFiltersEnum.ReportingPeriod,
        },
      },
      {
        field: 'reason_for_failure',
        headerName: 'Reason for failure',
        floatingFilterComponentParams: {
          useSearchFilter: true,
          showFilterDropdownButton: true,
          fieldName: TasksFiltersEnum.ReasonForFailure,
          fetchOptionsFunction: ProductionTaskService.getFailReasons,
        },
      },
    ],
  },
  {
    headerName: 'Assignment details',
    children: [
      {
        field: 'assignee',
        headerName: 'Assignee',
        editable: true,
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useSearchFilter: true,
          fieldName: TasksFiltersEnum.Assignee,
          fetchOptionsFunction: () => UserService.getAllUsers({ take: 100 }),
        },
      },
      {
        field: 'assignee_type',
        headerName: 'Assignee type',
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useSearchFilter: true,
          fieldName: TasksFiltersEnum.AssigneeType,
        },
      },
      {
        field: 'assignee_position',
        headerName: 'Assignee position',
        floatingFilterComponentParams: { useSelectFilter: true, fieldName: TasksFiltersEnum.AssigneePosition },
      },
      {
        field: 'assignee_department',
        headerName: 'Assignee department',
        floatingFilterComponentParams: { useSelectFilter: true, fieldName: TasksFiltersEnum.AssigneeDepartment },
      },
    ],
  },
  {
    headerName: 'Time',
    children: [
      {
        field: 'time_limit',
        headerName: 'Time limit',
        floatingFilterComponentParams: { showSearchInput: false },
      },
      {
        field: 'time_spent',
        headerName: 'Time spent',
        floatingFilterComponentParams: { showSearchInput: false },
      },
      {
        field: 'created_at',
        headerName: 'Created at',
        floatingFilterComponentParams: { showSearchInput: false },
      },
    ],
  },
  {
    headerName: 'Warnings',
    children: [
      {
        field: 'assignee_required',
        headerName: 'Assignee required',
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useSearchFilter: true,
          fieldName: TasksFiltersEnum.AssigneeRequired,
        },
      },
      {
        field: 'time_limit_exceeded',
        headerName: 'Time limit exceeded',
        floatingFilterComponentParams: {
          showSearchInput: false,
          showFilterDropdownButton: true,
          useSearchFilter: true,
          fieldName: TasksFiltersEnum.TimeLimitExceeded,
        },
      },
    ],
  },
  {
    children: [
      {
        headerName: '',
        cellRenderer: ActionsMenu,
        suppressMovable: true,
        width: 100,
        filter: false,
        sortable: false,
        editable: false,
        suppressMenu: true,
      },
    ],
  },
];

export const valueOptionsSelectedOptions = {
  value: '',
  options: [],
  selectedOptions: [],
};

export const valueOptionsSelectedOptionsWithSetting = {
  value: '',
  options: [],
  selectedOptions: [],
  filterSetting: FilterSettingEnum.Contains,
};

export const TASKS_PER_PAGE = 25;

export const TASKS_FIELDS = [
  'root_product_name',
  'root_product_version',
  'root_product_configuration',
  'root_product_variant',
  'product_name',
  'product_version',
  'product_configuration',
  'product_variant',
  'production_key',
  'root_production_key',
  'production_deadline',
  'root_production_deadline',
  'production_status',
  'production_progress',
  'order_key',
  'external_order_number',
  'marketplace_order_number',
  'client',
  'primary_client',
  'task_name',
  'task_key',
  'task_status',
  'task_priority',
  'task_type',
  'basic_reward',
  'total_bonus',
  'reporting_period',
  'is_in_queue',
  'is_failed',
  'reason_for_failure',
  'failed_at',
  'assigned_at',
  'assignee',
  'assignee_position',
  'assignee_department',
  'assignment_type',
  'time_limit',
  'time_spent',
  'created_at',
  'time_limit_exceeded',
  'assignee_required',
];
