import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { PositionSlotModel } from 'services/production-task.model';
import { IdName, MetaT, SortBy, SortOrderOption } from 'types/common-types';
import { PriorityEnum } from '../types/priority-enums';
import { ProductionStatusEnum, TaskStatusEnum } from '../types/status-enums';
import {
  ProductForProduction,
  ProductForProductionConfiguration,
  ProductForProductionVariant,
  ProductForProductionVersion,
  ProductForProductionWorkflow,
} from './products.model';

export type ProductionWorkflowCreateRequest = {
  order_id: string;
  product_variant_id: string;
  order_production_item_ids: string[];
};

export enum ProductionFilterCriteriaEnum {
  Client = 'client',
  Vendor = 'vendor',
  Issues = 'issues',
  Source = 'source',
  Option = 'option',
  Product = 'product',
  LimitBy = 'limit_by',
  TaskKey = 'task_key',
  OrderKey = 'order_key',
  MakeToStock = 'to_stock',
  CreatedBy = 'created_by',
  CreatedDate = 'created_at',
  StartedDate = 'started_at',
  Responsible = 'responsible',
  DeadlineDate = 'deadline_at',
  ProductType = 'product_type',
  SearchQuery = 'search_query',
  CompletedDate = 'completed_at',
  PrimaryClient = 'primary_client',
  OrderPriority = 'order_priority',
  ProductionKey = 'production_key',
  ShowCompleted = 'show_completed',
  ShowAllSubItems = 'show_all_sub_items',
  ProductionStatus = 'production_status',
  WorkflowName = 'workflow_template_name',
  InvolvedDepartment = 'involved_department',
  ProductionPriority = 'production_priority',
  ExternalOrderNumber = 'external_order_number',
  ConfigurationName = 'product_configuration_name',
  MarketplaceOrderNumber = 'marketplace_order_number',
  ExcludeUnknownProducts = 'exclude_unknown_products',
  ResponsibilityDepartment = 'responsibility_department',
  FirstWorkflowEstimatedTime = 'first_workflow_estimated_time',
  UsersAssignedToProductionTasks = 'users_assigned_to_production_tasks',
  ShowParentIfSubItemsMatchFilters = 'show_parent_items_if_sub_items_match_filters',
}
export type FilterCriteriasUnion = `${ProductionFilterCriteriaEnum}`;

export enum ProductionSortByEnum {
  Status = 'status',
  Progress = 'progress',
  CreatedAt = 'created_at',
  StartedAt = 'started_at',
  DeadlineAt = 'deadline_at',
  OrderPriority = 'priority',
  MakeToStock = 'to_stock',
  Responsible = 'responsible',
  EstimatedTime = 'estimated_time',
}
export enum GroupByEnum {
  None = 'none',
  Order = 'order',
  Product = 'product',
}
export enum ProductionWorkflowType {
  warehouse = 'warehouse',
  workflowTemplate = 'workflow_template',
}

export enum ShowCompletedPeriodEnum {
  All = 'all',
  None = 'none',
  Some = 'some',
}

export type ShowCompletedType = {
  period?: number;
  type: ShowCompletedPeriodEnum;
};

export type GetProductionWorkflowsFilteredRequest = {
  chosen_filters?: ProductionFilterCriteriaEnum[];
  filters: {
    [ProductionFilterCriteriaEnum.Vendor]: IdName[];
    [ProductionFilterCriteriaEnum.Issues]: string[];
    [ProductionFilterCriteriaEnum.Source]: string;
    [ProductionFilterCriteriaEnum.Option]: IdName[];
    [ProductionFilterCriteriaEnum.Client]: IdName[];
    [ProductionFilterCriteriaEnum.PrimaryClient]: IdName[];
    [ProductionFilterCriteriaEnum.ConfigurationName]: IdName[];
    [ProductionFilterCriteriaEnum.WorkflowName]: IdName[];
    [ProductionFilterCriteriaEnum.Product]: IdName[];
    [ProductionFilterCriteriaEnum.TaskKey]: IdName[];
    [ProductionFilterCriteriaEnum.OrderKey]: IdName[];
    [ProductionFilterCriteriaEnum.SearchQuery]: string;
    [ProductionFilterCriteriaEnum.CreatedBy]: IdName[];
    [ProductionFilterCriteriaEnum.ResponsibilityDepartment]: IdName[];
    [ProductionFilterCriteriaEnum.InvolvedDepartment]: IdName[];
    [ProductionFilterCriteriaEnum.ProductType]: IdName[];
    [ProductionFilterCriteriaEnum.Responsible]: IdName[];
    [ProductionFilterCriteriaEnum.ProductionKey]: IdName[];
    [ProductionFilterCriteriaEnum.ExternalOrderNumber]: IdName[];
    [ProductionFilterCriteriaEnum.OrderPriority]: string[];
    [ProductionFilterCriteriaEnum.MakeToStock]: boolean;
    [ProductionFilterCriteriaEnum.ProductionStatus]: string[];
    [ProductionFilterCriteriaEnum.ProductionPriority]: string[];
    [ProductionFilterCriteriaEnum.ExcludeUnknownProducts]: boolean;
    [ProductionFilterCriteriaEnum.ShowCompleted]: ShowCompletedType;
    [ProductionFilterCriteriaEnum.MarketplaceOrderNumber]: IdName[];
    [ProductionFilterCriteriaEnum.CreatedDate]: DateRange<Dayjs> | string[];
    [ProductionFilterCriteriaEnum.StartedDate]: DateRange<Dayjs> | string[];
    [ProductionFilterCriteriaEnum.UsersAssignedToProductionTasks]: IdName[];
    [ProductionFilterCriteriaEnum.DeadlineDate]: DateRange<Dayjs> | string[];
    [ProductionFilterCriteriaEnum.CompletedDate]: DateRange<Dayjs> | string[];
    [ProductionFilterCriteriaEnum.LimitBy]?: { by: 'order_key' | 'product_id'; value: string };
    [ProductionFilterCriteriaEnum.FirstWorkflowEstimatedTime]: { min: number | null; max: number | null };
  };
  sort: SortBy<ProductionSortByEnum>;
  group_by: GroupByEnum;
  show_parent_items_if_subitems_match_filters: boolean;
  show_all_subitems: boolean;
  pagination: {
    skip: number;
    take: number;
  };
};

export enum ProductionIssueEnum {
  UndefinedProduct = 'undefined_product',
  ProductionDeadlineExpired = 'production_deadline_expired',
  // ProductionOverdueTasks = 'production_overdue_tasks',
  TaskTimeLimitExceeded = 'task_time_limit_exceeded',
  TasksRequiringManualAssignment = 'tasks_requiring_manual_assignment',
  IssuesInNestedComponents = 'issues_in_nested_components',
}

export enum ProductionItemSourceEnum {
  Manual = 'manual',
  External = 'external',
}

export type ProductionWorkflowResponsibleT = {
  id: string;
  last_name: string;
  first_name: string;
  avatar_image_url: string;
  user_position_slots?: PositionSlotModel[];
};

export type ProductionWorkflow = {
  id: string;
  title: string;
  version: number;
  barcode: string;
  progress: number;
  created_at: string;
  product_id: string;
  is_external: boolean;
  product_type: string;
  product_name: string;
  priority: PriorityEnum;
  production_key: string;
  product_meta_id: string;
  finished_at: string | null;
  deadline_at: string | null;
  main_root_id: string | null;
  status: ProductionStatusEnum;
  issues: ProductionIssueEnum[];
  is_launch_in_progress: boolean;
  parentStatus?: ProductionStatusEnum;
  is_manual_assignmet_required: boolean;
  deadlineHistory: DeadlineHistoryItem[];
  nested_workflows: ProductionWorkflow[];
  is_any_task_time_limit_exceeded: boolean;
  additionalComponents: ProductionWorkflow[];
  responsible: ProductionWorkflowResponsibleT;
  parent_production_workflow_id: string | null;
  nested_production_component_has_issues: boolean;
  productionWorkflowItems: ProductionWorkflowItems[];
  nestedProductionWorkflowItems: NestedProductionWorkflowItem[];
  configuration: {
    id: string;
    name: string;
    sku: string;
  };
  variant: {
    sku: string;
    id: string;
    name: string;
  };
  order: {
    client?: {
      company: string;
      email: string;
      external_client_id: string;
      id: string;
      name: string;
      phone: string;
    };
    id: string;
    order_key: string;
    client_name?: string;
    priority: PriorityEnum;
    external_order_id: string;
    external_product_id: string;
    external_system_name: string;
    external_order_number?: string;
    marketplace_order_number?: string;
    to_stock?: boolean;
  };
  notes: NoteHistoryItem[];
  ordersHistory: OrdersHistoryItem[];
  variantsHistory: VariantHistoryItem[];
  externalOrderNumberHistory: ExternalOrderNumberHistoryItem[];
};

export type ProductionWorkflowOrderT = {
  id: string;
  client?: IdName;
  order_key: string;
  created_at: string;
  totalCount: number;
  is_deleted: boolean;
  order_number: string;
  external_order_number?: string;
  marketplace_order_number?: string;
  to_stock?: boolean;
  production_workflows: ProductionWorkflow[];
};

export type ProductionWorkflowProductT = {
  id: string;
  name: string;
  type: string;
  vendor: string;
  totalCount: number;
  production_workflows: ProductionWorkflow[];
};

export type ProductionWorkflowResponseDataT = ProductionWorkflow[] | ProductionWorkflowOrderT[] | ProductionWorkflowProductT[];

export type ProductionWorkflowResponse = {
  data: ProductionWorkflowResponseDataT;
  meta: MetaT;
};

export type ProductionWorkflowRequest = {
  filters: {};
  sort: {
    order: SortOrderOption;
    sortBy: ProductionSortByEnum;
  };
  group_by: GroupByEnum;
  show_parent_items_if_subitems_match_filters: boolean;
  show_all_subitems: boolean;
  pagination: {
    skip: number;
    take: number;
  };
};

export interface ProductionWorkflowLaunchData {
  type?: string;
  product_variant_id: string;
  workflow_template_id?: string;
  workflow_template_item_id?: string;
  production_workflow_id?: string;
  comment?: string;
  nested_workflows?: (NestedWorkflow | NestedWorkflowFromStock)[];
}

export type ProductionWorkflowMultiLaunchData = {
  type?: string;
  comment?: string;
  product_variant_id: string;
  workflow_template_id?: string;
  production_workflow_id?: string[];
  workflow_template_item_id?: string;
  nested_workflows?: (NestedWorkflow | NestedWorkflowFromStock)[];
};

export interface NestedWorkflowFromStock {
  type?: string;
  product_variant_id?: string;
  workflow_template_item_id?: string;
  comment?: string;
  nested_workflows?: (NestedWorkflow | NestedWorkflowFromStock)[];
}

export interface NestedWorkflow {
  type?: string;
  product_variant_id?: string;
  workflow_template_item_id: string;
  workflow_template_id?: string;
  nested_workflows?: (NestedWorkflow | NestedWorkflowFromStock)[];
}

export type ProductForLaunch = {
  id?: string;
  position: number;
  status: ProductionStatusEnum;
  version: ProductForProductionVersion;
  variant: ProductForProductionVariant;
  workflowType: ProductionWorkflowType | '';
  workflowTemplate: ProductForProductionWorkflow | null;
  configuration: ProductForProductionConfiguration;
  nested_for?: string;
  comment?: string;
  isContainsItself?: boolean;
} & ProductForProduction;

export type ProductionWorkflowTaskResponsibility = {
  id: string;
  reward_id: string;
  task_id: string;
  responsibility_count: number;
  name: string;
  assigment_type: string;
  created_at: string;
  taskSlots: ProductionWorkflowTaskSlot[];
};

export type ProductionWorkflowTaskSlot = {
  id: string;
  task_responsibility_id: string;
  task_assignment_id: string;
  taskResponsibility: ProductionWorkflowTaskResponsibility;
  taskAssignment: ProductionWorkflowTaskAssignment;
};

type ProductionWorkflowTaskAssignment = {
  id: string;
  user: ProductionWorkflowResponsibleT;
};

export type GetTaskIssuesArgs = {
  time_limit: number;
  total_in_progress_time: number;
  responsibilities: ProductionWorkflowTaskResponsibility[];
  is_additional: boolean;
};

export type ProductionWorkflowTaskT = {
  id: string;
  name: string;
  order: number;
  task_key: string;
  priority: string;
  failed_by: string;
  created_at: string;
  time_limit: number;
  description: string;
  is_control: boolean;
  basic_reward: number;
  is_reopened: boolean;
  is_in_queue: boolean;
  is_additional: boolean;
  status: TaskStatusEnum;
  departmets_all: IdName[];
  task_template_id: string;
  status_changed_at: string;
  total_paused_time: number;
  production_workflow_id: string;
  total_in_progress_time: number;
  total_in_progress_overtime: number;
  is_reporting_period_closed: boolean;
  reopened_tasks?: ProductionWorkflowTaskT[];
  total_paused_out_of_work_time_range: number;
  responsibilities: ProductionWorkflowTaskResponsibility[];
};

export type ProductionWorkflowItems = {
  id: string;
  position_x: number;
  position_y: number;
  was_changed: boolean;
  production_task_id: string;
  production_workflow_id: string;
  nested_production_workflow_id: string;
  task?: ProductionWorkflowTaskT;
  nestedProductionWorkflow?: {
    id: string;
    title: string;
    version: number;
    is_main: boolean;
    workflow: string;
    priority: string;
    progress: number;
    created_at: string;
    variant_name: string;
    configuration: string;
    production_key: string;
    responsible_id: string;
    deadline_at: string | null;
    finished_at: string | null;
    product_variant_id: string;
    status: ProductionStatusEnum;
    workflow_template_id: string;
    order_production_item_id: string;
    total_time_limit_with_nested: number;
    is_manual_assignmet_required: boolean;
    is_any_task_time_limit_exceeded: boolean;
    responsible: ProductionWorkflowResponsibleT;
    nested_production_component_has_issues: boolean;
  };
};

export type ProductionWorkflowInfo = {
  id: string;
  title: string;
  issues: any[];
  vendor: string;
  version: number;
  progress: number;
  created_at: string;
  started_at: string;
  deadline_at: string;
  finished_at: string;
  is_external: boolean;
  product_type: string;
  product_name: string;
  main_root_id?: string;
  priority: PriorityEnum;
  production_key: string;
  is_additional: boolean;
  product_meta_id: string;
  status: ProductionStatusEnum;
  is_launch_in_progress: boolean;
  additionalTasksProgress: number;
  parent_product_meta_ids: string[];
  is_root_workflow_completed: boolean;
  additionalComponentsProgress: number;
  deadlineHistory: DeadlineHistoryItem[];
  main_workflow_status?: ProductionStatusEnum;
  additionalComponents: ProductionWorkflow[];
  additionalTasks: ProductionWorkflowTaskT[];
  responsible: ProductionWorkflowResponsibleT;
  productionWorkflowItems: ProductionWorkflowItems[];
  prefer_auto_assign_users_with_prior_experience?: boolean;
  configuration: {
    id: string;
    sku: string;
    name: string;
  };
  variant: {
    id: string;
    sku: string;
    name: string;
  };
  order: {
    client: {
      company: string;
      email: string;
      external_client_id: string;
      id: string;
      name: string;
      phone: string;
    };
    id: string;
    order_key: string;
    client_name?: string;
    priority: PriorityEnum;
    external_order_id: string;
    external_product_id: string;
    external_system_name: string;
    external_order_number: string;
    marketplace_order_number?: string;
    to_stock?: boolean;
  };
  breadcrumbs: {
    id: string;
    type: string;
    name: string;
  }[];
  edges: {
    id: string;
    source_id: string;
    target_id: string;
  }[];
  notes: NoteHistoryItem[];
  variantsHistory: VariantHistoryItem[];
  externalOrderNumberHistory: ExternalOrderNumberHistoryItem[];
};

export type ProductionWorkflowUpdateBody = {
  title?: string;
  status?: string;
  is_main?: boolean;
  deadline_at?: string;
  production_key?: string;
  responsible_id?: string;
  priority?: PriorityEnum;
  product_variant_id?: string;
  workflow_template_id?: string;
  order_production_item_id?: string;
  total_time_limit_with_nested?: number;
  prefer_auto_assign_users_with_prior_experience?: boolean;
};

export type ProductionWorkflowUpdateBodyByHierarchy = {
  workflowsToUpdateDeadline: string[];
  deadline: string;
};

export type ProductionIssues = {
  issuesCount: number;
  mainProductionWorkflowIds: string[];
};

export type ProductionDetails = {
  id: string;
  production_key: string;
  product: {
    id: string;
    sku: string;
    name: string;
    barcode: string;
    variant: string;
    vendor: string;
    variant_sku: string;
    configuration: string;
    comment: string;
    product_variant_id: string;
    product_configuration_id: string;
    lastPublishedVersion: {
      id: string;
      name: string;
      version: number;
    };
  };
  hierarchy: {
    root_product_name: string;
    root_production_key: string;
    parent_production_key: string;
  };
  workflowTemplate: {
    id: string;
    name: string;
  };
  order: {
    id: string;
    client: IdName;
    order_key: string;
    to_stock?: boolean;
    is_deleted: boolean;
    primary_client: IdName;
    product_category: string;
    external_order_id: string;
    comment: string;
    external_order_number: string;
    marketplace_order_number: string;
  };
  time: {
    started_at: string;
    created_at: string;
    time_spent: number;
    deadline_at: string;
    estimated_time: number;
    estimated_time_with_components_tasks: number;
  };
  organization: {
    created_by: {
      avatar_image_url: string;
      first_name: string;
      id: string;
      last_name: string;
    };
    responsible_departments: string[];
    involved_departments: string[];
    performers: { id: string; first_name: string; last_name: string; avatar_image_url: string }[];
  };
  tasks: {
    tasksCount: number;
    componentsCount: number;
    tasksWithComponentsTasksCount: number;
  };
  cost: {
    estimated_cost: number;
    estimated_cost_without_nested_components: number;
  };
  notes: NoteHistoryItem[];
  clientHistory: ClientHistoryItem[];
  ordersHistory: OrdersHistoryItem[];
  variantsHistory: VariantHistoryItem[];
  deadlineHistory: DeadlineHistoryItem[];
  externalOrderNumberHistory: ExternalOrderNumberHistoryItem[];
  marketplaceOrderNumberHistory: MarketplaceOrderNumberHistoryItem[];
};

export type CanCancelProductionResponse = {
  canBeCanceled: boolean;
  components: Array<IdName & { status: ProductionStatusEnum }>;
  tasks: Array<IdName & { status: ProductionStatusEnum }>;
};

export type NestedComponentsResponse = {
  components: Array<IdName & { status: ProductionStatusEnum }>;
};

export type DeadlineHistoryItem = {
  created_at: string;
  created_by: string;
  id: string;
  new_deadline: string;
  previous_deadline: string;
  production_workflow_id: string;
  changed_by_external_system: boolean;
  user: {
    avatar_image_url: string;
    first_name: string;
    id: string;
    last_name: string;
  };
};

export type ComponentHistoryItem = {
  action: string;
  created_at: string;
  production_key: string;
  title: string;
  user: {
    avatar_image_url: string;
    first_name: string;
    id: string;
    last_name: string;
  };
};

export type updateWorkflowDetailsRequestBody = {
  product_variant_id?: string;
  order_id?: string;
  replace_variant_in_components?: boolean;
  note?: string;
  product_configuration?: string;
};

export type UpdateProductionWorkflowByOrderBody = {
  current_order_id: string;
  new_order_id: string;
};

export type HistoryUser = {
  id: string;
  last_name: string;
  first_name: string;
  avatar_image_url: string;
};

export type OrdersHistoryItem = {
  created_at: string;
  created_by: string;
  id: string;
  newOrder: {
    client_id: string;
    counterparty_id: string;
    created_at: string;
    deadline_at: string;
    external_created_at: string;
    external_order_id: string;
    external_order_number: string;
    id: string;
    marketplace_order_number: string;
    order_key: string;
    priority: string;
  };
  newOrderKey: string;
  new_order_id: string;
  previousOrder: {
    client_id: string;
    counterparty_id: string;
    created_at: string;
    deadline_at: string;
    external_created_at: string;
    external_order_id: string;
    external_order_number: string;
    id: string;
    marketplace_order_number: string;
    order_key: string;
    priority: string;
  };
  previousOrderKey: string;
  previous_order_id: string;
  production_workflow_id: string;
  user: HistoryUser;
};

export type ExternalOrderNumberHistoryItem = {
  created_at: string;
  created_by: string;
  id: string;
  new_external_order_number: string;
  order_id: string;
  previous_external_order_number: string;
  user: HistoryUser;
};

export type MarketplaceOrderNumberHistoryItem = {
  created_at: string;
  created_by: string;
  id: string;
  new_marketplace_order_number: string;
  order_id: string;
  previous_marketplace_order_number: string;
  user: HistoryUser;
};

export type ClientHistoryItem = {
  created_at: string;
  created_by: string;
  id: string;
  newClient: string;
  new_client_id: string;
  order_id: string;
  previousClient: string;
  previous_client_id: string;
  user: HistoryUser;
};

export type VariantHistoryItem = {
  created_at: string;
  created_by: string;
  id: string;
  newVariant: string;
  new_variant_id: string;
  previousVariant: string;
  previous_variant_id: string;
  production_workflow_id: string;
  user: HistoryUser;
};

export type NoteHistoryItem = {
  created_at: string;
  created_by: string;
  id: string;
  is_configuration_change: boolean;
  note: string;
  production_workflow_id: string;
  user: HistoryUser;
};

export type SetChosenFiltersBody = {
  chosen_filters: ProductionFilterCriteriaEnum[];
};

export type ChangesLogObj = {
  production_workflow_id?: string;
  is_moved_out_of_root?: boolean;
  production_workflow_item_id_to_attach?: string;
  is_moved_to_additional_components?: boolean;
  attached_to?: string;
  production_workflow_id_to_replace?: string;
  detached_from?: string;
  components_to_add?: { product_variant_id: string }[];
};

export type NestedProductionWorkflowItem = {
  id: string;
  nestedProductionWorkflow: {
    created_at: string;
    deadline_at: string;
    finished_at: string;
    id: string;
    is_any_task_time_limit_exceeded: boolean;
    is_main: any;
    is_manual_assignmet_required: boolean;
    nested_production_component_has_issues: boolean;
    order_production_item_id: string;
    priority: PriorityEnum;
    product_variant_id: string;
    production_key: string;
    progress: number;
    responsible: any;
    responsible_id: string;
    status: ProductionStatusEnum;
    title: string;
    total_time_limit_with_nested: number;
    workflow_template_id: string;
  };
  nested_production_workflow_id: string;
  position_x: number;
  position_y: number;
  production_task_id: string;
  production_workflow_id: string;
  task: any;
};

export type ManageTaskPriorityRequestBody = {
  priority: PriorityEnum;
  apply_changes_to_components: boolean;
};

export type AssignResponsibleToProductionsBody = {
  responsible_id: string;
  production_ids: string[];
};

export type ProductionWorkflowHierarchy = {
  id: string;
  is_main: boolean;
  production_key: string;
  deadline_at: string;
  product_name: string;
  is_additional: boolean;
};

export type GetProductionWorkflowHierarchyArgs = {
  id: string;
  status?: ProductionStatusEnum[];
};
