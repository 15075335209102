import { useRef } from 'react';
import s from './file-upload-button.module.scss';

type Props = {
  title: string;
  accept?: string;
  shouldOpenFileDialog?: boolean;
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
  onFileSelect?: (file: File | null) => void;
};

const FileUploadButton = ({ title, accept, variant = 'primary', shouldOpenFileDialog = true, onFileSelect, onClick }: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (shouldOpenFileDialog && fileInputRef.current) {
      fileInputRef.current.click();
    }

    onClick?.();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!files.length) return;

    onFileSelect(files[0]);
  };

  return (
    <>
      <button className={s.button} data-variant={variant} type="button" onClick={handleButtonClick}>
        {title}
      </button>
      <input className={s.input} ref={fileInputRef} type="file" accept={accept} onChange={handleFileChange} />
    </>
  );
};

export default FileUploadButton;
