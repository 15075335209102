import { TASKS_FIELDS } from 'pages/tasks/constants';
import { TasksFiltersEnum, TasksFiltersT } from 'pages/tasks/types';
import { IdName } from 'types/common-types';

const extractSelectedOptions = <T>(field: { selectedOptions: T[] }): T[] | undefined => {
  return field.selectedOptions.length > 0 ? field.selectedOptions : undefined;
};

const extractBooleanSelectedOptions = (field: { selectedOptions: string[] }): boolean | null => {
  const { selectedOptions } = field;

  if (!selectedOptions.length) return null;

  const allTrue = selectedOptions.every((option) => option === 'true');
  const allFalse = selectedOptions.every((option) => option === 'false');

  if (allTrue) return true;

  if (allFalse) return false;

  return null;
};

const extractMatchingOptions = (field: { selectedOptions: string[]; options: IdName[] }) => {
  return field.options.filter((option) => field.selectedOptions.includes(option.id));
};

const cleanObject = (obj: Record<string, any>) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      value !== 0 &&
      !(Array.isArray(value) && (value.length === 0 || value.every((v) => v === null)))
    ) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const generateRequestBody = (filters: TasksFiltersT) => {
  return {
    fields: TASKS_FIELDS,
    filters: cleanObject({
      // Production
      [TasksFiltersEnum.ProductionStatus]: extractSelectedOptions(filters.filters[TasksFiltersEnum.ProductionStatus]),
      [TasksFiltersEnum.ProductionDeadline]: filters.filters[TasksFiltersEnum.ProductionDeadline].value,
      [TasksFiltersEnum.RootProductionDeadline]: filters.filters[TasksFiltersEnum.RootProductionDeadline].value,

      // Task
      [TasksFiltersEnum.IsInQueue]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.IsInQueue]),
      [TasksFiltersEnum.IsFailed]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.IsFailed]),
      [TasksFiltersEnum.FailedAt]: filters.filters[TasksFiltersEnum.FailedAt].value,
      [TasksFiltersEnum.TaskType]: extractSelectedOptions(filters.filters[TasksFiltersEnum.TaskType]),
      [TasksFiltersEnum.TaskStatus]: extractSelectedOptions(filters.filters[TasksFiltersEnum.TaskStatus]),
      [TasksFiltersEnum.TaskPriority]: extractSelectedOptions(filters.filters[TasksFiltersEnum.TaskPriority]),
      [TasksFiltersEnum.ReportingPeriod]: filters.filters[TasksFiltersEnum.ReportingPeriod].value,
      [TasksFiltersEnum.ReasonForFailure]: extractSelectedOptions(filters.filters[TasksFiltersEnum.ReasonForFailure]),

      // Assignment
      [TasksFiltersEnum.Assignee]: extractMatchingOptions(filters.filters[TasksFiltersEnum.Assignee]),
      [TasksFiltersEnum.AssigneeType]: extractSelectedOptions(filters.filters[TasksFiltersEnum.AssigneeType]),

      // Warnings
      [TasksFiltersEnum.AssigneeRequired]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.AssigneeRequired]),
      [TasksFiltersEnum.TimeLimitExceeded]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.TimeLimitExceeded]),
    }),
    queries: cleanObject({
      // Product
      [TasksFiltersEnum.ProductName]: filters.queries[TasksFiltersEnum.ProductName],
      [TasksFiltersEnum.ProductVersion]: Number(filters.queries[TasksFiltersEnum.ProductVersion]),
      [TasksFiltersEnum.RootProductName]: filters.queries[TasksFiltersEnum.RootProductName],
      [TasksFiltersEnum.RootProductVersion]: Number(filters.queries[TasksFiltersEnum.RootProductVersion]),
      [TasksFiltersEnum.RootProductVariant]: filters.queries[TasksFiltersEnum.RootProductVariant],
      [TasksFiltersEnum.RootProductConfiguration]: filters.queries[TasksFiltersEnum.RootProductConfiguration],

      // Production
      [TasksFiltersEnum.ProductionKey]: filters.queries[TasksFiltersEnum.ProductionKey],
      [TasksFiltersEnum.ProductVariant]: filters.queries[TasksFiltersEnum.ProductVariant],
      [TasksFiltersEnum.RootProductionKey]: filters.queries[TasksFiltersEnum.RootProductionKey],
      [TasksFiltersEnum.ProductConfiguration]: filters.queries[TasksFiltersEnum.ProductConfiguration],

      // Order
      [TasksFiltersEnum.Client]: filters.queries[TasksFiltersEnum.Client],
      [TasksFiltersEnum.OrderKey]: filters.queries[TasksFiltersEnum.OrderKey],
      [TasksFiltersEnum.TaskName]: filters.queries[TasksFiltersEnum.TaskName],
      [TasksFiltersEnum.PrimaryClient]: filters.queries[TasksFiltersEnum.PrimaryClient],
      [TasksFiltersEnum.ReasonForFailure]: filters.queries[TasksFiltersEnum.ReasonForFailure],
      [TasksFiltersEnum.ExternalOrderNumber]: filters.queries[TasksFiltersEnum.ExternalOrderNumber],
      [TasksFiltersEnum.MarketPlaceOrderNumber]: filters.queries[TasksFiltersEnum.MarketPlaceOrderNumber],

      // Assignment
      [TasksFiltersEnum.AssigneePosition]: filters.queries[TasksFiltersEnum.AssigneePosition],
      [TasksFiltersEnum.AssigneeDepartment]: filters.queries[TasksFiltersEnum.AssigneeDepartment],
    }),
  };
};
