import { baseAxiosInstance } from 'axios-config';

import { TaskTableModel } from 'services/task-table.model';
import { PaginateResponse } from 'types/paginate-response';

export class TaskTableService {
  public static async getAllTask(body: any, params?: { skip?: number; take?: number }) {
    const { data } = await baseAxiosInstance.post<PaginateResponse<TaskTableModel>>('/tasks/all', body, { params });

    return data;
  }
}
